import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect } from "react";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CustomizedSnackbars = (props) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.onClose();
  };

  if (props.type === "success") {
    return (
      <Snackbar
        open={props.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
          style={{ backgroundColor: "#1AB524" }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    );
  } else if (props.type === "info") {
    return (
      <Snackbar
        open={props.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
          {props.message}
        </Alert>
      </Snackbar>
    );
  } else if (props.type === "error") {
    return (
      <Snackbar
        open={props.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {props.message}
        </Alert>
      </Snackbar>
      )
  } 
  else return null;
}