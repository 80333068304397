import React, { useEffect } from "react";

import { Button, Img, Text } from "components";
import PluginFileCoverOneLogo from "components/PluginFileCoverOneLogo";
import { PageFooter } from "components/PageFooter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getInfo } from "slices/infoSlice";

const RegisterendPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInfo());
  }, []);

  return (
    <>
      <div className="bg-white_A700 flex flex-col font-poppins sm:gap-10 gap-[100px] md:gap-10 items-center justify-center mx-auto p-[18px] w-full h-[100%]">
        <div className="flex flex-col md:gap-10 gap-[100px] items-start justify-start max-w-[1440px] mx-auto md:px-5 w-full">
          <PluginFileCoverOneLogo
            className="flex flex-col items-start justify-start md:w-[100%] w-[auto]"
            cheetah="CareerDog"
          />
          <div className="bg-white_A700 flex items-center justify-center max-w-[1439px] mt-[-55.7px] mx-[auto] sm:px-[20px]  w-[100%] z-[1]">
            <div className="flex flex-col gap-[100px] items-center justify-start w-[100%]">
              <Text
                className="text-center text-gray_900 tracking-[-3.60px] w-auto"
                as="h1"
                variant="h1"
              >
                Welcome
              </Text>
              <Text className="leading-[150.00%] max-w-[460px] md:max-w-full not-italic text-center text-gray_900 tracking-[0.32px]">
                Great Start! We have built a dashboard for your profile, you can
                edit it anytime. Would you like to simply pitch yourself once
                and for all?
              </Text>
              <div className="flex flex-row items-center justify-center w-[100%] py-[60px] max-w-[1680px]">
                <div className="flex sm:flex-col flex-row gap-[30px] items-center justify-center mr-[100px] w-[auto]">
                  <Img
                    src="images/img_triangle_gray_50.svg"
                    className="h-[26px] w-[26px]"
                    alt="triangle_Three"
                  />
                  <Img
                    src="images/img_triangle_orange_100.svg"
                    className="h-[50px] w-[50px]"
                    alt="triangle_Four"
                  />
                  <Img
                    src="images/img_send.svg"
                    className="h-[90px] w-[90px]"
                    alt="send_One"
                  />
                  <Img
                    src="images/img_triangle.svg"
                    className="h-[128px] w-[128px]"
                    alt="triangle_Five"
                  />
                </div>
                <div className="flex flex-col items-center gap-[26px] w-[500px]">
                  <Button
                    onClick={() => {
                      // localStorage.setItem("skip", "true");
                      navigate("/surveyresume")}}
                    className="bg-gray_900 cursor-pointer font-semibold sm:px-[20px] px-[26px] py-[12px] rounded-[8px] shadow-bs sm:text-[22px] md:text-[24px] text-[26px] text-center text-white_A700 tracking-[-0.26px] w-[380px]"
                  >
                    Take a job quiz
                  </Button>
                  {/* <Text
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      "fontSize": "24px",
                      "lineHeight": "150%",
                      "letter-spacing": "-0.01em",
                      "text-align": "center",
                      color: "#000000",
                      weight: "500px",
                    }}
                    onClick={() => {
                      // localStorage.setItem("skip", "true");
                      navigate("/dashboardprofile")}}
                  >
                    Skip quiz, let’s see my empty Dashboard
                  </Text> */}
                </div>
                <div className="flex sm:flex-col flex-row gap-[30px] items-center justify-center ml-[100px] w-[auto]">
                  <Img
                    src="images/img_triangle.svg"
                    className="h-[128px] w-[128px]"
                    alt="triangle"
                  />
                  <Img
                    src="images/img_send.svg"
                    className="h-[90px] w-[90px]"
                    alt="send"
                  />
                  <Img
                    src="images/img_triangle_orange_100.svg"
                    className="h-[50px] w-[50px]"
                    alt="triangle_One"
                  />
                  <Img
                    src="images/img_triangle_gray_50.svg"
                    className="h-[26px] w-[26px]"
                    alt="triangle_Two"
                  />
                </div>
              </div>
            </div>
          </div>
          <PageFooter />
        </div>
      </div>
    </>
  );
};

export default RegisterendPage;
