import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text, Button, Img } from "components";
import SignupOneColumnemail from "components/SignupOneColumnemail";
import PluginFileCoverOneLogo from "components/PluginFileCoverOneLogo";
import { useNavigate } from "react-router-dom";
import { userForgotPassword, userValidateEmail } from "slices/userSlice";

const ValidateEmailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.user.status);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  useEffect(() => {
    dispatch(userValidateEmail({ token }));
  }, []);

  return (
    <>
      {/* 根据不同的status，给出不同的提示语 */}
      {status === "succeeded" ? (
        <p> &nbsp;Your email has been validated. You can now login to your account.</p>
      ) : status === "failed" ? (
        <p> &nbsp;Failed to validate your email.</p>
      ) : (
        <p> &nbsp;Validating your email...</p>
      )}
    </>
  );
};

export default ValidateEmailPage;
