import React from "react";

import { Text, Input, Button, Img } from "components";

const SurveyWorkSurveycard = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="border-[2px] border-gray_900 border-solid flex md:flex-col flex-row gap-[16px] items-start justify-start px-[16px] py-[26px] rounded-[8px] w-[100%]">
          <div className="flex sm:flex-1 flex-col gap-[8px] items-start justify-start self-stretch sm:w-[100%] w-[auto]">
            <Text
              className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins font-semibold px-[16px] py-[8px] text-bluegray_400 text-left tracking-[-0.13px] w-[380px]"
              as="h5"
              variant="h5"
            >
              {props?.textarea}
            </Text>
            <Text
              className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-normal font-poppins not-italic px-[16px] py-[8px] text-bluegray_400 text-left tracking-[-0.13px] w-[380px]"
              as="h5"
              variant="h5"
            >
              {props?.textarea_One}
            </Text>
            <div className="flex sm:flex-col flex-row gap-[20px] items-start justify-start self-stretch sm:w-[100%] w-[auto]">
              <Input
                wrapClassName="bg-white_A700 border-b-[2px] border-gray_900 border-solid px-[16px] py-[8px] sm:w-[100%] w-[48%]"
                className="font-normal font-poppins not-italic p-[0] placeholder:text-bluegray_400 text-[16px] text-bluegray_400 text-left tracking-[-0.08px] w-[100%]"
                name="textarea_Two"
                placeholder="Location"
              ></Input>
              <Input
                wrapClassName="bg-white_A700 border-b-[2px] border-gray_900 border-solid px-[16px] py-[8px] sm:w-[100%] w-[48%]"
                className="font-normal font-poppins not-italic p-[0] placeholder:text-bluegray_400 text-[16px] text-bluegray_400 text-left tracking-[-0.08px] w-[100%]"
                name="textarea_Three"
                placeholder="Country"
              ></Input>
            </div>
            <div className="flex sm:flex-col flex-row gap-[20px] items-start justify-start self-stretch sm:w-[100%] w-[auto]">
              <Input
                wrapClassName="bg-white_A700 border-b-[2px] border-gray_900 border-solid px-[16px] py-[8px] sm:w-[100%] w-[48%]"
                className="font-normal font-poppins not-italic p-[0] placeholder:text-bluegray_400 text-[16px] text-bluegray_400 text-left tracking-[-0.08px] w-[100%]"
                name="textarea_Four"
                placeholder="Start date"
              ></Input>
              <Input
                wrapClassName="bg-white_A700 border-b-[2px] border-gray_900 border-solid px-[16px] py-[8px] sm:w-[100%] w-[48%]"
                className="font-normal font-poppins not-italic p-[0] placeholder:text-bluegray_400 text-[16px] text-bluegray_400 text-left tracking-[-0.08px] w-[100%]"
                name="textarea_Five"
                placeholder="End Date"
              ></Input>
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-[16px] h-[100%] items-start justify-start w-[100%]">
            <div className="border-b-[2px] border-gray_900 border-solid flex h-[100%] items-start justify-start p-[8px] w-[100%]">
              <Text
                className="font-poppins leading-[150.00%] not-italic text-bluegray_400 text-left"
                variant="body7"
              >
                {props?.describeafewwor_One}
              </Text>
            </div>
            <Button
              className="bg-yellow_900 cursor-pointer flex items-center justify-center min-w-[108px] px-[20px] py-[8px] rounded-[8px] w-[auto]"
              leftIcon={
                <Img
                  src="images/img_arrowdown.svg"
                  className="mt-[1px] mb-[2px] mr-[8px]"
                  alt="arrow_down"
                />
              }
            >
              <div className="font-poppins font-semibold text-[16px] text-center text-gray_900 tracking-[-0.08px]">
                {props?.save}
              </div>
            </Button>
          </div>
        </div>
        <div className="border-[2px] border-gray_900 border-solid flex flex-row gap-[16px] items-center justify-center sm:px-[20px] px-[26px] py-[12px] rounded-[8px] shadow-bs w-[100%]">
          <div className="border-[2px] border-gray_900 border-solid h-[30px] rounded-[50%] w-[30px]"></div>
          <Text
            className="font-poppins font-semibold text-center text-gray_900 tracking-[-0.26px] w-[auto]"
            as="h5"
            variant="h5"
          >
            {props?.signup}
          </Text>
        </div>
        <div className="flex sm:flex-col flex-row sm:gap-[40px] items-start justify-between w-[100%]">
          <div className="flex sm:flex-1 items-start justify-start sm:w-[100%] w-[auto]">
            <a
              className="font-poppins font-semibold sm:text-[32px] md:text-[34px] text-[36px] text-gray_900 text-left tracking-[-0.72px] underline w-[auto]"
              href=""
            >
              {props?.previous}
            </a>
          </div>
          <div className="flex sm:flex-1 items-center justify-start sm:w-[100%] w-[auto]">
            <a
              className="font-poppins font-semibold sm:text-[32px] md:text-[34px] text-[36px] text-gray_900 text-left tracking-[-0.72px] underline w-[auto]"
              href=""
            >
              {props?.next}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

SurveyWorkSurveycard.defaultProps = {
  textarea: "COMPANY NAME",
  textarea_One: "Your Title",
  describeafewwor_One: (
    <>
      Describe a few words <br />
      e.g. Responsibility
    </>
  ),
  save: "Save",
  signup: "Add anohter",
  previous: "Previous",
  next: "Next",
};

export default SurveyWorkSurveycard;
