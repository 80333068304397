import React from "react";
import { useState } from "react";
import { Text, Img, Input } from "components";

const SignupOneColumnemail = (props) => {
  const [selected, setSelected] = useState(false);

  return (
    <>
      <div className={props.className}>
        <Text
          className="font-poppins font-semibold text-gray_900 text-left tracking-[-0.08px] pb-[6px] w-[auto]"
          variant="body3"
        >
          {props?.email}
        </Text>
        <div
          style={selected == true ? {
            "borderBottom":"2px solid #F28B31", 
            "background": "#FCF8F5"
          } : {
            "borderBottom":"2px solid #1b1b1b",
            "background": "white"
          } }
          className="border-b-[2px] border-solid flex flex-row gap-[10px] items-start justify-start sm:px-[20px] px-[24px] py-[10px] md:w-[100%] w-[410px]">
          <Input 
            wrapClassName="w-[100%] flex flex-row gap-[10px] items-center justify-start"
            className="flex-1 font-normal not-italic text-bluegray_400 text-left tracking-[0.32px] text-[16px]"
            style={{
              color: "#000",
              padding: "8px 8px 0px 0px",
              "box-shadow": selected == false?  "0 0 0px 1000px white inset" : "0 0 0px 1000px #FCF8F5 inset",
              "input:placeholder": "white"
            }}
            onFocus={() => setSelected(true)}
            onBlur={() => setSelected(false)}
            maxLength="256"
            placeholder={props.value === "" ? props.defaultValue : ""}
            required=""
            value={props.value}
            onChange={props.onChange}
            type={props.type}
            autoComplete="current-password"
            suffix={props.suffix}
          />
        </div>
      </div>
    </>
  );
};

SignupOneColumnemail.defaultProps = { email: "Email*" };

export default SignupOneColumnemail;
