import React, { useEffect } from "react";

import DashboardProfileProfilenav from "components/DashboardProfileProfilenav";
import { PageFooter } from "components/PageFooter";
import { CustomizedTable, headCells } from "pages/Dashboardjobsearch";
import { TableContainer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getJobHistory } from "slices/jobSlice";
import { getInfo } from "slices/infoSlice";

const DashboardapplicationPage = () => {
  const dispatch = useDispatch();
  const ref = React.useRef(null);
  const rows = useSelector((state) => state.job.applications)
  const status = useSelector((state) => state.job.status)
  const endFlag = useSelector((state) => state.job.endFlag)
  const [displayRows, setDisplayRows] = React.useState(rows);
  const [startFlag, setStartFlag] = React.useState(false);

  useEffect(() => {
    if (!startFlag) {
      setStartFlag(true);
    }
    setDisplayRows(rows);
  }, [rows, status]);

  // 处理滚动条问题
  useEffect(() => {
    const styleTags = document.querySelectorAll("style");
    styleTags.forEach((styleTag) => {
      if (styleTag.textContent.includes("::-webkit-scrollbar")) {
        styleTag.parentNode.removeChild(styleTag);
      }
    });

    const handleBeforeUnload = () => {
      const styleTag = document.createElement("style");
      styleTag.textContent = `
            ::-webkit-scrollbar {
                display: none;
            }
            `;
      document.head.appendChild(styleTag);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    dispatch(getJobHistory({offset:0}) )
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      const styleTag = document.createElement("style");
      styleTag.textContent = `
            ::-webkit-scrollbar {
                display: none;
            }
            `;
      document.head.appendChild(styleTag);
    };
  }, []);

  // 处理滚动监听问题
  useEffect(() => {
    if (ref.current == null) {
      return;
    }
    const handleScroll = () => {
      if (ref.current) {
        const { scrollTop, clientHeight, scrollHeight } = ref.current;
        if (scrollTop + clientHeight + 10 >= scrollHeight) {
          if (endFlag || status == "loading") {
            return;
          }
          dispatch(getJobHistory({offset:displayRows.length}))
        }
      }
    };
    ref.current.addEventListener("scroll", handleScroll);
    return () => {
      if (ref.current != null){
        ref.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ref, displayRows, endFlag, status]);

  return (
    <>
      <div className="flex flex-col font-poppins items-center justify-start mx-[auto] w-[100%] min-h-[100%] h-[auto]">
        <DashboardProfileProfilenav/>
        <TableContainer
            className="flex"
            ref={ref}
            style={{
              maxHeight: "calc(100vh - 166px)",
              padding: "0 60px 0 60px ",
              justifyContent: "center",
            }}
          >
            <CustomizedTable
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "1540px",
                overflow: "scroll",
              }}
              headCells={headCells}
              rows={displayRows}
            />
          </TableContainer>
          {/* PageFooter 可以使用CSS strict positioning */}
          <div style={{"position":"absolute", "width":"100%", "bottom": 0, "left":0, "right":0, "height":"60px"}}>
           <PageFooter/>
          </div>
      </div>
    </>
  );
};

export default DashboardapplicationPage;
