import { configureStore, createSlice, current } from "@reduxjs/toolkit";
import homeReducer from "slices/homeSlice.js";
import userReducer from "slices/userSlice.js";
import infoReducer from "slices/infoSlice.js";
import jobReducer from "slices/jobSlice.js";

export default configureStore({
  reducer: {
    home: homeReducer,
    user: userReducer,
    info: infoReducer,
    job: jobReducer
  },
});