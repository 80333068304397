import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { ErrorMessage } from "../../components/ErrorMessage";

const NewInput = React.forwardRef(
  (
    {
      className = "",
      name,
      placeholder,
      type = "text",
      children,
      errors = [],
      label = "",
      prefix,
      suffix,
      newWindow,
      ...restProps
    },
    ref
  ) => {
    const [selected, setSelected] = useState(false);
    return (
      <>
          {!!label && label}
          {!!prefix && prefix}
          <input
            ref={ref}
            className={`${className} bg-transparent border-0`}
            type={type}
            name={name}
            style={selected == true ? {
                "borderBottom":"2px solid #F28B31", 
                "background": "#FCF8F5"
              } : {
                "borderBottom":"2px solid #1b1b1b",
                "background": "white"
              } }
            onFocus={() => setSelected(true)}
            onBlur={() => setSelected(false)}
            autoComplete={restProps?.autoComplete || "off"}
            placeholder={placeholder}
            {...restProps}
          />
          {!!suffix && suffix}
      </>
    );
  }
);

NewInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

NewInput.defaultProps = {
  className: "",
  name: "",
  placeholder: "",
  type: "text",
};

export { NewInput };
