import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text, Button, Img, Line } from "components";
import SignupOneColumnemail from "components/SignupOneColumnemail";
import PluginFileCoverOneLogo from "components/PluginFileCoverOneLogo";
import { useNavigate } from "react-router-dom";
import { userLogin, userResendEmail } from "slices/userSlice";
import { CustomizedSnackbars } from "components/Alert";
import axios from "axios";

const ResendPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const { status } = useSelector((state) => state.user);
  const [time, setTime] = React.useState(60);
  const [resendFlag, setResendFlag] = React.useState(false);
  const [currentStatus, setCurrentStatus] = React.useState("others");
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (urlParams.get("email")) {
      setEmail(urlParams.get("email"));
    }
    if (currentStatus != "others" || urlParams.get("email")) {
      setCurrentStatus(status);
      if (status === "succeeded") {
        setTime(60);
        const timer = setInterval(() => {
          setTime((time) => time - 1);
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          setCurrentStatus("idle");
        }, 60000);
        setResendFlag(true);
      }
    } else {
      setCurrentStatus("idle");
    }
  }, [status]);

  const handleRegister = () => {
    dispatch(userResendEmail({ email: email }));
  };

  return (
    <>
      <div
        className="bg-white_A700 flex sm:flex-col md:flex-col flex-row font-poppins sm:gap-[40px] md:gap-[40px] h-[100%] items-start justify-start mx-[auto] sm:pl-[20px] md:pl-[40px] self-stretch sm:w-[100%] md:w-[100%] w-[auto]"
        style={{ overflow: "hidden" }}
      >
        <div className="flex flex-col h-[100%] items-center justify-between w-[60%] px-[15%] py-[1%]">
          <div className="flex items-center justify-start md:w-[100%] w-[auto]">
            <a
              className="font-normal not-italic text-[12px] text-bluegray_400 text-left underline w-[auto] cursor-pointer"
              onClick={() => navigate("/")}
            >
              Back to homepage
            </a>
          </div>
          <div
            className="flex h-[100%] md:h-[auto] items-center justify-start sm:w-[100%] w-[auto]"
          >
            <div className="flex flex-col gap-[26px] items-start justify-center self-stretch w-[auto]">
              <div className="flex flex-col font-poppins gap-[3px] items-start justify-start self-stretch w-[auto]">
                <Text
                  className="text-center text-gray_900 tracking-[-0.72px] w-[auto]"
                  as="h3"
                  variant="h3"
                >
                  Resend Email
                </Text>
                <Text
                  className="not-italic text-bluegray_400 text-left w-[auto]"
                  variant="body7"
                >
                  Please enter your email address to resend the verification
                  email.
                </Text>
              </div>
              <div className="flex flex-col gap-[16px] items-start justify-start self-stretch sm:w-[100%] w-[auto]">
                <div className="flex font-poppins items-start justify-start self-stretch sm:w-[100%] w-[auto]">
                  <SignupOneColumnemail
                    className="flex flex-col items-start justify-start self-stretch sm:w-[100%] w-[auto]"
                    email="Email"
                    // defaultValue="email@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <a
                  className="common-pointer font-bold font-dmsans text-[14px] text-left text-yellow_900 tracking-[-0.28px] underline w-[auto]"
                  onClick={() => navigate("/login")}
                >
                 Sign In
                </a>
              {currentStatus === "loading" ? (
                <Button
                  className="bg-gray_900 cursor-pointer font-semibold sm:px-[20px] px-[26px] py-[12px] rounded-[8px] sm:text-[22px] md:text-[24px] text-[26px] text-center text-white_A700 tracking-[-0.26px] w-[410px]"
                  style={{ backgroundColor: "#8C8C8C" }}
                  disabled="disabled"
                >
                  Please wait... &nbsp;&nbsp;{" "}
                  <Img src="images/cd-logo-white-46.svg" />
                </Button>
              ) : currentStatus === "succeeded" ? (
                <>
                  <Button
                    className="bg-gray_900 cursor-pointer font-semibold sm:px-[20px] px-[26px] py-[12px] rounded-[8px] sm:text-[22px] md:text-[24px] text-[26px] text-center text-white_A700 tracking-[-0.26px] w-[410px]"
                    style={{ backgroundColor: "#8C8C8C" }}
                    disabled="disabled"
                  >
                    <Img src="images/img_check_circle.svg" /> &nbsp; Email Sent.
                    &nbsp;&nbsp; <Img src="images/cd-logo-white-46.svg" />
                  </Button>
                  <Text
                    className="common-pointer font-bold font-dmsans text-gray_900 text-left tracking-[-0.28px] w-[auto]"
                    variant="body6"
                  >
                    Wait for {time} seconds to resend the email.
                  </Text>
                </>
              ) : (
                <Button
                  onClick={() => {
                    handleRegister();
                  }}
                  className="bg-gray_900 cursor-pointer font-semibold sm:px-[20px] px-[26px] py-[12px] rounded-[8px] shadow-bs sm:text-[22px] md:text-[24px] text-[26px] text-center text-white_A700 tracking-[-0.26px] w-[410px]"
                >
                  Resend Email &nbsp;&nbsp;&nbsp;
                  <Img src="images/cd-logo-white-46.svg" />
                </Button>
              )}
            </div>
          </div>
          <Text
            className="font-normal not-italic text-gray_401 text-left tracking-[0.32px] w-[auto]"
            variant="body3"
          >
            © 2023 CareerDog. All Rights Reserved.{" "}
          </Text>
        </div>
        <div className="h-[100%] relative md:w-[100%] w-[55%]">
          <Img
            src="images/img_background54_1006x829.png"
            className="h-[100%] m-[auto] object-cover rounded-bl-[200px] rounded-br-[0] rounded-tl-[0] rounded-tr-[0] w-[100%]"
            alt="backgroundFiftyFour"
          />
          <PluginFileCoverOneLogo
            color="white"
            className=" absolute flex  flex-col inset-x-[0] items-center justify-start mx-[auto] sm:px-[20px] px-[21px] rounded-bl-[200px] rounded-br-[0] rounded-tl-[0] rounded-tr-[0] top-[40%] sm:w-[100%] w-[auto] text-white_A700"
            cheetah="CareerDog"
          />
        </div>
      </div>
    </>
  );
};

export default ResendPage;
