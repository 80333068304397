import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { handleRequest } from "../API.js";

export const userRegister = createAsyncThunk("userRegister", async (params) => {
  return await handleRequest(async () => {
    const response = await API.register(params.email, params.password);
    return response.data;
  });
});

export const userLogin = createAsyncThunk("userLogin", async (params) => {
  return await handleRequest(async () => {
    const response = await API.login(params.email, params.password);
    return response.data;
  });
});

export const userForgotPassword = createAsyncThunk(
  "userForgotPassword",
  async (email) => {
    return await handleRequest(async () => {
      const response = await API.forgot_password(email);
      return response.data;
    });
  }
);

export const userResetPassword = createAsyncThunk(
  "userResetPassword",
  async ({ token, password }) => {
    return await handleRequest(async () => {
      const response = await API.reset_password(token, password);
      return response.data;
    });
  }
);

export const userValidateEmail = createAsyncThunk(
  "userValidateEmail",
  async ({ token }) => {
    return await handleRequest(async () => {
      const response = await API.validate_email(token);
      return response.data;
    });
  }
);

export const userResendEmail = createAsyncThunk(
  "userResendEmail",
  async ({ email }) => {
    return await handleRequest(async () => {
      const response = await API.resend_email(email);
      return response.data;
    });
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    status: "idle",
  },
  extraReducers(builder) {
    builder
      .addCase(userRegister.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userRegister.fulfilled, (state, action) => {
        state.status = "succeeded";
        setTimeout(() => {
          alert("success", "Verification email sent successfully!");
        }, 100);
      })
      .addCase(userRegister.rejected, (state, action) => {
        state.status = "failed";
        setTimeout(() => {
          alert("error", action?.error?.message ?? "Something went wrong!");
        }, 100);
      })
      .addCase(userResendEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userResendEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
        setTimeout(() => {
          alert("success", "Verification email sent successfully!");
        }, 100);
      })
      .addCase(userResendEmail.rejected, (state, action) => {
        state.status = "failed";
        setTimeout(() => {
          alert("error", action?.error?.message ?? "Something went wrong!");
        }, 100);
      })
      .addCase(userLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.status = "succeeded";
        localStorage.setItem("access", action.payload.access);
        localStorage.setItem("refresh", action.payload.refresh);
        localStorage.setItem("open", true);
        localStorage.setItem("message", "You logged in successfully!");
        localStorage.setItem("type", "success");
        window.location.href = "/dashboardprofile";
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.status = "failed";
        setTimeout(() => {
          alert("error", action?.error?.message ?? "Something went wrong!");
        }, 100);
      })
      .addCase(userForgotPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userForgotPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        setTimeout(() => {
          alert("success", "Password reset email sent successfully!");
        }, 100);
      })
      .addCase(userForgotPassword.rejected, (state, action) => {
        state.status = "failed";
        setTimeout(() => {
          alert("error", action?.error?.message ?? "Something went wrong!");
        }, 100);
      })
      .addCase(userResetPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userResetPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        localStorage.setItem("open", true);
        localStorage.setItem("message", "Password reset successfully!");
        localStorage.setItem("type", "success");
        window.location.href = "/login";
      })
      .addCase(userResetPassword.rejected, (state, action) => {
        state.status = "failed";
        setTimeout(() => {
          alert("error", action?.error?.message ?? "Something went wrong!");
        }, 100);
      })
      .addCase(userValidateEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userValidateEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
        localStorage.setItem("open", true);
        localStorage.setItem("message", "Email verified successfully!");
        localStorage.setItem("type", "success");
        window.location.href = "/login";
      })
      .addCase(userValidateEmail.rejected, (state, action) => {
        state.status = "failed";
        setTimeout(() => {
          alert("error", action?.error?.message ?? "Something went wrong!");
        }, 100);
      });
  },
});

export default userSlice.reducer;
