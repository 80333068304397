import React, { useEffect } from "react";
import PluginFileCoverOneLogo from "components/PluginFileCoverOneLogo";
import { Text, Button, Img, Input, NewInput } from "components";
import SurveyResumeSurveycard from "components/SurveyResumeSurveycard";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInfo, getParsedInfo, getResumes, putInfo } from "slices/infoSlice";

export function areDictionariesEqual(dict1, dict2) {
  const keys1 = Object.keys(dict1);
  const keys2 = Object.keys(dict2);

  // 判断字典的键数量是否相同
  if (keys1.length !== keys2.length) {
    return false;
  }

  // 判断字典的每个键值对是否相同
  for (let key of keys1) {
    const value1 = dict1[key];
    const value2 = dict2[key];

    // 判断值的类型
    if (typeof value1 === 'object' && typeof value2 === 'object') {
      if (!areDictionariesEqual(value1, value2)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
}


const SurveyresumePage = () => {
  const ori_contact = useSelector((state) => state.info.info.contact_info);
  const dispatch = useDispatch();
  const ori_info = useSelector((state) => state.info.info);
  const [contact, setContact] = React.useState(ori_contact);
  const navigate = useNavigate();

  const parsed_status = useSelector((state) => state.info.get_parsed_status);
  const parsed_info = useSelector((state) => state.info.parsed_info);
  const latest_resume_id = useSelector((state) => state.info.resumes);
  const onClickFit = () => {
    dispatch(getParsedInfo(latest_resume_id[latest_resume_id.length - 1]["id"]));
  }
  useEffect(() => {
    if (parsed_status === "succeeded") {
      // new info
      const new_info = {...contact}
      new_info["first_name"] = parsed_info["First Name"] ?? new_info["first_name"];
      new_info["last_name"] = parsed_info["Last Name"] ?? new_info["last_name"];
      new_info["email"] = parsed_info["Email"] ?? new_info["email"];
      new_info["phone"] = parsed_info["Phone Number"] ?? new_info["phone"];
      new_info["city"] = parsed_info["City"] ?? new_info["city"];
      new_info["location"] = parsed_info["State/Province"] ?? new_info["location"];
      new_info["country"] = parsed_info["Country"] ?? new_info["country"];
      new_info["postal_code"] = parsed_info["Postal code"] ?? new_info["postal_code"];
      setContact(new_info);
    } 
  }, [parsed_status]);

  const onChange = (e, type) => {
    setContact({ ...contact, [type]: e.target.value });
  };  

  useEffect(() => {
    dispatch(getInfo());
    dispatch(getResumes());
  }, []);

  useEffect(() => {
    if (ori_contact !== contact) {
      setContact(ori_contact);
    }
  }, [ori_contact]);

  const onClickNext = () => {
    if (contact.first_name === "") {
      alert("info", "Please enter your first name");
      return;
    }
    if (contact.last_name === "") {
      alert("info", "Please enter your last name");
      return;
    }
    if (contact.email === "" || !contact?.email?.includes("@")) {
      alert("info", "Please enter a valid email address");
      return;
    }
    let info = { ...ori_info, contact_info: contact }
    if (!areDictionariesEqual(info, ori_info)) {
      dispatch(putInfo(info));
    }
    navigate("/surveyedu");
  };

  return (
    <>
      <div
        className="relative bg-orange_100 flex flex-col font-poppins gap-[55px] h-[100%] sm:gap-[40px] md:gap-[40px] items-start justify-start mx-[auto] px-[120px] sm:px-[20px] md:px-[40px] self-stretch sm:w-[100%] md:w-[100%] w-[auto]"
        style={{ paddingTop: "20px" }}
      >
        <PluginFileCoverOneLogo
          className="flex flex-col items-start justify-start md:w-[100%] w-[auto]"
          cheetah="CareerDog"
        />
        <div className="flex items-center max-w-[1440px] mx-[auto] w-[100%] h-[100%] md:h-[auto]  self-stretch md:flex-col flex-row md:gap-[20px] ">
          <div
            className="flex md:flex-1 flex-col md:gap-[40px] items-start justify-start md:w-[100%] w-[44%]"
            style={{ paddingTop: "60px" }}
          >
            <div className="flex flex-col h-[40%] md:h-[auto] items-start">
              <Text
                className="font-medium md:max-w-[100%] max-w-[472px] text-gray_900 text-left tracking-[-1.50px]"
                as="h2"
                variant="h2"
              >
                Let’s fill out your <br /> contact info.
              </Text>
              <Text
                className="max-w-[380px] pt-[26px] pb-[94px]"
                style={{"fontSize": "12px", "lineHeight": "150%", "fontWeight": 400, "fontStyle": "normal", "fontFamily": "Poppins"}}
              >
                This information is only visible to you, and is used only to
                help you autofill job applications. &nbsp;
                <a
                  style={{"color": "#8c8c8c", "textDecoration": "none", "cursor": "pointer" }}        
                >
                  Learn how we handle your data
                </a>
              </Text>
              <Button
                onClick={onClickFit}
                className="bg-gray_900 cursor-pointer font-semibold sm:px-[20px] px-[26px] py-[12px] rounded-[8px] shadow-bs sm:text-[22px] md:text-[24px] text-[26px] text-center text-white_A700 tracking-[-0.26px] w-[380px]">
                Fill it for me &nbsp;&nbsp;{" "}
                <Img src="/images/cd-logo-white-46.svg" />
              </Button>
              <div
                className="flex flex-row gap-[20px] items-start justify-start self-stretch w-[auto]"
                style={{ paddingBottom: "25%", paddingTop: "10%" }}
              >
                <Img
                  src="/images/img_map.svg"
                  className="h-[31px] rounded-[7px] w-[auto]"
                  alt="map"
                />
                <Img
                  src="/images/img_location_white_a700.svg"
                  className="h-[52px] w-[23px]"
                  alt="location"
                />
                <Img
                  src="/images/img_rectangle657.svg"
                  className="h-[31px] rounded-[7px] w-[auto]"
                  alt="map_One"
                />
                <Img
                  src="/images/img_signal.svg"
                  className="h-[52px] w-[23px]"
                  alt="signal_One"
                />
                <Img
                  src="/images/img_rectangle657.svg"
                  className="h-[31px] rounded-[7px] w-[auto]"
                  alt="rectangle657_One"
                />
                <Img
                  src="/images/img_signal.svg"
                  className="h-[52px] w-[23px]"
                  alt="signal_Two"
                />
                <Img
                  src="/images/img_rectangle657.svg"
                  className="h-[31px] rounded-[7px] w-[auto]"
                  alt="rectangle657_Two"
                />
              </div>
            </div>
          </div>
          <div
            className="bg-white_A700 flex flex-1 flex-col h-[100%] md:h-[auto] items-center max-w-[892px] sm:px-[20px] gap-[10%] md:px-[40px] px-[72px] shadow-bs w-[100%]"
            style={{
              "borderTopLeftRadius": "150px",
              "borderTopRightRadius": "150px",
            }}
          >
            <div
              style={{ paddingTop: "15%" }}
              className="flex flex-col md:gap-[40px] h-[80%] md:h-[auto] items-start justify-start sm:w-[100%] w-[auto] gap-[8%]"
            >
              <div>
                <Text
                  className="font-poppins font-semibold text-black_900 text-left tracking-[-0.08px] w-[auto]"
                  variant="body2"
                >
                  Name
                  <span className="text-red_400">*</span>
                </Text>
                <div className="flex sm:flex-col flex-row gap-[10px] items-center justify-center self-stretch sm:w-[100%] w-[auto]">
                  <div className="flex h-[40px] md:h-[auto] items-start justify-start w-[200px]">
                    <NewInput
                      className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[200px]"
                      value={contact.first_name}
                      onChange={(e) => onChange(e, "first_name")}
                      placeholder="First Name"
                      maxLength="256"
                    />
                  </div>
                  <div className="flex h-[40px] md:h-[auto] items-start justify-start w-[200px]">
                    <NewInput
                      className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[200px]"
                      value={contact.last_name}
                      onChange={(e) => onChange(e, "last_name")}
                      placeholder="Last Name"
                      maxLength="256"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[2px] items-start justify-start self-stretch sm:w-[100%] w-[auto]">
                <Text
                  className="font-poppins font-semibold text-gray_900 text-left tracking-[-0.08px] w-[auto]"
                  variant="body2"
                >
                  Email
                  <span className="text-red_400">*</span>
                </Text>
                <NewInput
                  className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[100%]"
                  value={contact.email}
                  onChange={(e) => onChange(e, "email")}
                  placeholder="example@gmail.com"
                  maxLength="256"
                />
              </div>
              <div className="flex flex-col gap-[2px] items-start justify-start self-stretch sm:w-[100%] w-[auto]">
                <Text
                  className="font-poppins font-semibold text-gray_900 text-left tracking-[-0.08px] w-[auto]"
                  variant="body2"
                >
                  Phone Number
                </Text>
                <NewInput
                  className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[100%]"
                  value={contact.phone}
                  onChange={(e) => onChange(e, "phone")}
                  placeholder="123 456 7890"
                  maxLength="256"
                />
              </div>
              <div>
                <Text
                  className="font-poppins font-semibold text-black_900 text-left tracking-[-0.08px] w-[auto]"
                  variant="body2"
                >
                  Location
                </Text>
                <div className="flex sm:flex-col flex-row gap-[10px] items-center justify-center self-stretch sm:w-[100%] w-[auto]">
                  <div className="flex h-[40px] md:h-[auto] items-start justify-start w-[200px]">
                    <NewInput
                      className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[200px]"
                      value={contact.city}
                      onChange={(e) => onChange(e, "city")}
                      placeholder="City"
                      maxLength="256"
                    />
                  </div>
                  <div className="flex h-[40px] md:h-[auto] items-start justify-start w-[200px]">
                    <NewInput
                      className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[200px] "
                      value={contact.location}
                      onChange={(e) => onChange(e, "location")}
                      placeholder="State/Province"
                      maxLength="256"
                    />
                  </div>
                </div>
                <div className="flex sm:flex-col flex-row gap-[10px] items-center justify-center self-stretch sm:w-[100%] w-[auto] pt-[5%]">
                  <div className="flex h-[40px] md:h-[auto] items-start justify-start w-[200px]">
                    <NewInput
                      className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[200px]"
                      value={contact.country}
                      onChange={(e) => onChange(e, "country")}
                      placeholder="Country"
                      maxLength="256"
                    />
                  </div>
                  <div className="flex h-[40px] md:h-[auto] items-start justify-start w-[200px]">
                    <NewInput
                      className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[200px]"
                      value={contact.postal_code}
                      onChange={(e) => onChange(e, "postal_code")}
                      placeholder="Postal code"
                      maxLength="256"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row sm:gap-[40px] items-start justify-between w-[100%]">
              <a
                style={{ cursor: "pointer" }}
                className="font-poppins font-semibold sm:text-[32px] md:text-[34px] text-[36px] text-left tracking-[-0.72px] underline w-[auto]"
                onClick={() => {
                  navigate("/surveyresume");
                }}
              >
                Previous
              </a>
              <a
                style={{ cursor: "pointer" }}
                className="font-poppins font-semibold sm:text-[32px] md:text-[34px] text-[36px] text-gray_900 text-left tracking-[-0.72px] underline w-[auto]"
                onClick={() => onClickNext()}
              >
                Next
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyresumePage;
