import React from "react";

import PluginFileCoverOneLogo from "components/PluginFileCoverOneLogo";
import { Text, Button, Img, Input, NewTextArea } from "components";
import SurveyWorkSurveycard from "components/SurveyWorkSurveycard";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dict } from "pages/Surveyedu";
import { Popover } from "@mui/material";
import DatePicker from "components/Datepicker";
import { useEffect } from "react";
import { getInfo, getParsedInfo, getResumes, putInfo } from "slices/infoSlice";
import { areDictionariesEqual } from "pages/Surveycontactinfo";

export const emptyWork = {
  idx: 0,
  // company_name: "",
  // title: "",
  // location: "",
  // country: "",
  // start_date: "",
  // end_date: "",
  description: "",
};

const SurveyworkPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear()
  );
  const [currentMonth, setCurrentMonth] = React.useState(
    dict[new Date().getMonth() + 1]
  );
  const [currentType, setCurrentType] = React.useState("start_date");
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const ori_work = useSelector((state) => state.info.info.work_experience);
  const ori_info = useSelector((state) => state.info.info);
  const [work, setWork] = React.useState(ori_work);

  const parsed_status = useSelector((state) => state.info.get_parsed_status);
  const parsed_info = useSelector((state) => state.info.parsed_info);
  const latest_resume_id = useSelector((state) => state.info.resumes);
  const onClickFit = () => {
    dispatch(getParsedInfo(latest_resume_id[latest_resume_id.length - 1]["id"]));
  }
  useEffect(() => {
    if (parsed_status === "succeeded") {
      // new info
      const new_info = [{
        idx: 0,
        description: parsed_info["Work Experience"],
      }]
      setWork(new_info);
    } 
  }, [parsed_status]);


  useEffect(() => {
    dispatch(getInfo());
    dispatch(getResumes());
  }, []);

  useEffect(() => {
    setWork(ori_work);
  }, [ori_work]);

  function checkIfChanged(index) {
    if (work[index] != ori_work[index]) {
      return true;
    }
    return false;
  }

  const onChange = (e, type, index) => {
    setWork([
      ...work.slice(0, index),
      {
        ...work[index],
        [type]: e.target.value,
      },
      ...work.slice(index + 1),
    ]);
  };

  const addAnother = () => {
    setWork([...work, emptyWork]);
  };

  const removeWork = (index) => {
    setWork([...work.slice(0, index), ...work.slice(index + 1)]);
  };

  const onClickDate = (e, type, index) => {
    if (work[index][type]) {
      const date = work[index][type].split(" ");
      setCurrentMonth(date[0]);
      setCurrentYear(parseInt(date[1]));
    }
    setCurrentType(type);
    setCurrentIndex(index);
    setAnchorEl(e.currentTarget);
  };

  const onCloseDate = (type, index) => {
    setAnchorEl(null);
    if (currentMonth && currentYear) {
      setWork([
        ...work.slice(0, index),
        {
          ...work[index],
          [type]: `${currentMonth} ${currentYear}`,
        },
        ...work.slice(index + 1),
      ]);
    } else {
      setWork([
        ...work.slice(0, index),
        {
          ...work[index],
          [type]: "",
        },
        ...work.slice(index + 1),
      ]);
    }
  };

  const onClickPrev = () => {
    navigate("/surveyedu");
  };

  const onClickNext = () => {
    let info = {
      ...ori_info,
      work_experience: work.filter(
        (item) =>
          item.company_name !== "" ||
          item.title !== "" ||
          item.location !== "" ||
          item.country !== "" ||
          item.start_date !== "" ||
          item.end_date !== "" ||
          item.description !== ""
      ),
    };
    if (!areDictionariesEqual(info, ori_info)){
      dispatch(putInfo(info));
    }
    navigate("/surveylink");
  };

  return (
    <>
      <div
        className="relative bg-orange_100 flex flex-col font-poppins gap-[55px] h-[100%] sm:gap-[40px] md:gap-[40px] items-start justify-start mx-[auto] px-[120px] sm:px-[20px] md:px-[40px] self-stretch sm:w-[100%] md:w-[100%] w-[auto]"
        style={{ paddingTop: "20px" }}
      >
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => onCloseDate(currentType, currentIndex)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "start",
          }}
        >
          <DatePicker
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
            onClose={() => onCloseDate(currentType, currentIndex)}
          />
        </Popover>
        <PluginFileCoverOneLogo
          className="flex flex-col items-start justify-start md:w-[100%] w-[auto]"
          cheetah="CareerDog"
        />
        <div className="flex items-center max-w-[1440px] mx-[auto] w-[100%] h-[100%] md:h-[auto]  self-stretch md:flex-col flex-row md:gap-[20px] ">
          <div
            className="flex md:flex-1 flex-col md:gap-[40px] items-start justify-start md:w-[100%] w-[44%]"
            style={{ paddingTop: "60px" }}
          >
            <div className="flex flex-col h-[40%] md:h-[auto] items-start">
              <Text
                className="font-medium md:max-w-[100%] max-w-[472px] text-gray_900 text-left tracking-[-1.50px]"
                as="h2"
                variant="h2"
              >
                Tell us about your Work Experience
              </Text>
              <Text
                className="max-w-[380px] pt-[26px] pb-[94px]"
                style={{"fontSize": "12px", "lineHeight": "150%", "fontWeight": 400, "fontStyle": "normal", "fontFamily": "Poppins"}}
              >
                This information is only visible to you, and is used only to
                help you autofill job applications. &nbsp;
                <a
                  style={{"color": "#8c8c8c", "textDecoration": "none", "cursor": "pointer" }}        
                >
                  Learn how we handle your data
                </a>
              </Text>
              <Button
                onClick={onClickFit} 
                className="bg-gray_900 cursor-pointer font-semibold sm:px-[20px] px-[26px] py-[12px] rounded-[8px] shadow-bs sm:text-[22px] md:text-[24px] text-[26px] text-center text-white_A700 tracking-[-0.26px] w-[380px]">
                Fill it for me &nbsp;&nbsp;{" "}
                <Img src="/images/cd-logo-white-46.svg" />
              </Button>
              <div
                className="flex flex-row gap-[20px] items-start justify-start self-stretch w-[auto]"
                style={{ paddingBottom: "25%", paddingTop: "10%" }}
              >
                <Img
                  src="/images/img_map.svg"
                  className="h-[31px] rounded-[7px] w-[auto]"
                  alt="map"
                />
                <Img
                  src="/images/img_location_white_a700.svg"
                  className="h-[52px] w-[23px]"
                  alt="location"
                />
                <Img
                  src="/images/img_map.svg"
                  className="h-[31px] rounded-[7px] w-[auto]"
                  alt="map_One"
                />
                <Img
                  src="/images/img_location_white_a700.svg"
                  className="h-[52px] w-[23px]"
                  alt="location_One"
                />
                <Img
                  src="/images/img_rectangle657.svg"
                  className="h-[31px] rounded-[7px] w-[auto]"
                  alt="map_Two"
                />
                <Img
                  src="/images/img_signal.svg"
                  className="h-[52px] w-[23px]"
                  alt="signal"
                />
                <Img
                  src="/images/img_rectangle657.svg"
                  className="h-[31px] rounded-[7px] w-[auto]"
                  alt="rectangle657"
                />
              </div>
            </div>
          </div>
          <div
            className="bg-white_A700 flex flex-1 flex-col h-[100%] md:h-[auto] items-center max-w-[892px] sm:px-[20px] md:px-[40px] px-[72px] shadow-bs w-[100%] gap-[30px]"
            style={{
              "borderTopLeftRadius": "150px",
              "borderTopRightRadius": "150px",
              paddingTop: "10%",
            }}
          >
            <div
              style={{ height: window.innerHeight * 0.45 }}
              className="flex flex-col gap-[20px] overflow-auto w-[100%] items-center"
            >
              {work.length == 0 && (
                <Text as="h4" variant="h4" className="w-[100">
                  Looks empty here.
                </Text>
              )}
              {work.length > 0 &&
                work.map((item, index) => {
                  return (
                    <div className="border-[2px] border-gray_900 border-solid flex md:flex-col flex-row gap-[16px] items-start justify-start px-[16px] py-[26px] rounded-[8px] w-[100%]">
                      {/* <div className="flex sm:flex-1 flex-col gap-[8px] items-start justify-start self-stretch sm:w-[100%] w-[50%]">
                        <NewInput
                          className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[100%]"
                          value={work[index].company_name}
                          onChange={(e) => onChange(e, "company_name", index)}
                          placeholder="Company Name"
                          maxLength="256"
                        />
                        <NewInput
                          className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[100%]"
                          value={work[index].title}
                          onChange={(e) => onChange(e, "title", index)}
                          placeholder="Your Title"
                          maxLength="256"
                        />
                        <div className="flex sm:flex-col flex-row gap-[20px] items-start justify-start self-stretch sm:w-[100%] w-[auto]">
                          <NewInput
                            className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[100%]"
                            value={work[index].location}
                            onChange={(e) => onChange(e, "location", index)}
                            placeholder="Location"
                            maxLength="256"
                          />
                          <NewInput
                            className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[100%]"
                            value={work[index].country}
                            onChange={(e) => onChange(e, "country", index)}
                            placeholder="Country"
                            maxLength="256"
                          />
                        </div>
                        <div className="flex sm:flex-col flex-row gap-[20px] items-start justify-start self-stretch sm:w-[100%] w-[auto]">
                          <NewInput
                            className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[100%]"
                            value={work[index].start_date}
                            placeholder="Start Date"
                            maxLength="256"
                            onClick={(e) => {
                              onClickDate(e, "start_date", index);
                            }}
                          />
                          <NewInput
                            className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins h-[50px] not-italic px-[16px] py-[6px]  text-left w-[100%]"
                            value={work[index].end_date}
                            placeholder="End Date"
                            maxLength="256"
                            onClick={(e) => {
                              onClickDate(e, "end_date", index);
                            }}
                          />
                        </div>
                      </div> */}
                      <div className="flex flex-1 flex-col gap-[16px] h-[100%] items-start justify-end w-[100%]">
                        <NewTextArea
                          className="bg-white_A700 border-b-[2px] border-gray_900 border-solid font-poppins leading-[150.00%] h-[100px] not-italic px-[16px] pt-[2px] pb-[2px]  text-left w-[100%]"
                          value={work[index].description}
                          onChange={(e) => onChange(e, "description", index)}
                          placeholder={"Describe a few words about this work experience \ne.g. Position title, company, duration, responsibility, collaboration, etc."}
                          // maxLength="256"
                        />
                        <div className="flex flex-row gap-[18px]">
                          {/* <Button className="bg-yellow_900 cursor-pointer flex items-center justify-center min-w-[80px] px-[20px] py-[8px] rounded-[8px] w-[auto]">
                            <div className="font-poppins font-semibold text-[16px] text-center text-gray_900 tracking-[-0.08px]">
                              Save
                            </div>
                          </Button> */}
                          <Button
                            className="cursor-pointer flex items-center justify-center min-w-[80px] px-[20px] py-[8px] rounded-[8px] w-[auto]"
                            style={{ border: "1px solid #E5684C" }}
                            onClick={() => removeWork(index)}
                          >
                            <div
                              className="font-poppins text-[16px] text-center tracking-[-0.08px]"
                              style={{ color: "#E5684C" }}
                            >
                              Delete
                            </div>
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div
                onClick={addAnother}
                className="border-[2px] cursor-pointer border-gray_900 border-solid flex flex-row gap-[16px] items-center justify-center sm:px-[20px] px-[26px] py-[12px] rounded-[8px] w-[100%]"
              >
                <Img src="/images/img_add_another.svg" />
                <Text
                  className="font-poppins font-semibold text-center text-gray_900 tracking-[-0.26px] w-[auto]"
                  as="h5"
                  variant="h5"
                >
                  Add another
                </Text>
              </div>
            </div>
            <div className="flex flex-row sm:gap-[40px] items-start justify-between w-[100%] pt-[14%]">
              <a
                className="font-poppins cursor-pointer font-semibold sm:text-[32px] md:text-[34px] text-[36px] text-left tracking-[-0.72px] underline w-[auto]"
                onClick={onClickPrev}
              >
                Previous
              </a>
              <a
                className="font-poppins cursor-pointer font-semibold sm:text-[32px] md:text-[34px] text-[36px] text-gray_900 text-left tracking-[-0.72px] underline w-[auto]"
                onClick={onClickNext}
              >
                Next
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyworkPage;
