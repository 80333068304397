import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { handleRequest } from "../API.js";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const getReserve = createAsyncThunk(
  "getReserve",
  async () => {
    return await handleRequest(async () => {
      const response = await API.reserve_info();
      return response.data;
    });
  }
);

export const postReserve = createAsyncThunk("postReserve", async (email) => {
  return await handleRequest(async () => {
    const response = await API.reserve(email);
    return response.data;
  });
});

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    status: "idle",
    count: 0,
  },
  extraReducers(builder) {
    builder
      .addCase(getReserve.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReserve.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.count = action.payload.count;
      })
      .addCase(getReserve.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(postReserve.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postReserve.fulfilled, (state, action) => {
        state.status = "succeeded";
        setTimeout(() => {
          if (action.payload.count) {
            alert("success", "You reserved successfully!")
          } else {
            alert("info", "You already reserved!")
          }
        }, 100);
      })
      .addCase(postReserve.rejected, (state, action) => {
        state.status = "failed";
        setTimeout(() => {
          alert("error", action?.error?.message ?? "Something went wrong!")
        }, 100);
      })
  },
});

export default homeSlice.reducer;
