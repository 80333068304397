import React from "react";
import PluginFileCoverOneLogo from "components/PluginFileCoverOneLogo";
import { Text, Img, Button } from "components";
import { useNavigate } from "react-router-dom";


const DashboardProfileProfilenav = (props) => {
  const navigate = useNavigate();
  const urlSuffix =
    window.location.href.split("/")[window.location.href.split("/").length - 1];
  const isProfile =
    urlSuffix == "dashboardprofile" || urlSuffix == "dashboardsetting";

  return (
    <>
      <header className="flex items-center justify-between md:px-[20px] h-[106px] px-[40px] py-[20px] w-[100%]">
        <PluginFileCoverOneLogo
          className="flex flex-col items-center justify-center md:w-[100%] w-[auto] h-[100%]"
          cheetah="CareerDog"
        />
        {isProfile ? (
          <ul 
          className="flex mb-[0px] flex-row md:hidden items-center justify-between max-w-[1000px] px-[20px] py-[8px] shadow-bs6 rounded-[33px] w-[100%] common-row-list h-[100%]">
            <li className="sm:w-[100%] sm:my-[10px] w-[auto]">
              <Img
                src="images/img_user_gray_900.svg"
                className="h-[50px] w-[50px]"
                alt="user"
              />
            </li>
            <li className="mt-[4px] mb-[6px] sm:w-[100%] sm:my-[10px] w-[auto]">
              <Button
                onClick={() => navigate("/dashboardprofile")}
                style={
                  urlSuffix == "dashboardprofile"
                    ? { backgroundColor: "#F0802B", color: "#FFFFFF" }
                    : null
                }
                className=" cursor-pointer font-semibold hover:justify-center px-[16px] sm:text-[22px] md:text-[24px] text-[26px] text-center text-gray_900 tracking-[-0.26px] w-[180px] rounded-[23px]"
              >
                {props?.profile}
              </Button>
            </li>
            <li className="mt-[4px] mb-[6px] sm:w-[100%] sm:my-[10px] w-[auto]">
              <Button
                onClick={() => navigate("/dashboardjobsearch")}
                className=" cursor-pointer font-semibold hover:justify-center px-[16px] sm:text-[22px] md:text-[24px] text-[26px] text-center text-gray_900 tracking-[-0.26px] w-[180px] rounded-[23px]"
              >
                Jobs
              </Button>
            </li>
            <li className="sm:w-[100%] sm:my-[10px] w-[auto] my-[1px]">
              <Button
                onClick={() => navigate("/dashboardsetting")}
                className=" cursor-pointer font-semibold justify-center py-[2px] rounded-[23px] sm:text-[22px] md:text-[24px] text-[26px] text-center text-gray_900 tracking-[-0.26px] w-[180px]"
                style={
                  urlSuffix == "dashboardsetting"
                    ? { backgroundColor: "#F0802B", color: "#FFFFFF" }
                    : null
                }
              >
                {props?.settings}
              </Button>
            </li>
            <li className="sm:w-[100%] sm:my-[10px] w-[auto] my-[1px]">
              <Button
                onClick={() => navigate("/surveyresume")}
                className=" cursor-pointer font-semibold justify-center py-[2px] rounded-[23px] sm:text-[22px] md:text-[24px] text-[26px] text-center text-gray_900 tracking-[-0.26px] w-[180px]"
              >
                Update
              </Button>
            </li>
          </ul>
        ) : (
          <Button
            onClick={() => navigate("/dashboardprofile")}
            className="hover:bg-white cursor-pointer font-semibold px-[15px] py-[8px] rounded-[33px] sm:text-[22px] md:text-[24px] text-[26px] text-center text-gray_900 tracking-[-0.26px] w-[200px]"
          >
            <Img
              src="images/img_user_gray_900.svg"
              className="h-[50px] w-[50px] tems-center justify-center"
              alt="user"
            />
            &nbsp;&nbsp;&nbsp;Profile
          </Button>
        )}
        {isProfile ? (
          // <Button
          //   onClick={() => navigate("/dashboardjobsearch")}
          //   className="hover:bg-white cursor-pointer font-semibold justify-center px-[20px] py-[8px] rounded-[33px] sm:text-[22px] md:text-[24px] text-[26px] text-center text-gray_900 tracking-[-0.26px] w-[200px]"
          // >
          //   Jobs&nbsp;&nbsp;&nbsp;
          //   <Img
          //     src="images/img_globe_gray_900.svg"
          //     className="h-[50px] w-[50px] tems-center justify-center"
          //     alt="globe"
          //   />
          // </Button>
            <Img
              src="images/img_globe_gray_900.svg"
              className="h-[50px] w-[50px] tems-center justify-center"
              alt="globe"
            />
        ) : (
          <ul 
          className="flex mb-[0px] flex-row md:hidden items-center justify-between max-w-[800px] px-[20px] py-[8px] rounded-[33px] shadow-bs6 w-[100%] common-row-list h-[100%]">
          <li className="mt-[4px] mb-[6px] sm:w-[100%] sm:my-[10px] w-[auto]">
            <Button
              onClick={() => navigate("/dashboardjobsearch")}
              style={
                urlSuffix.indexOf("dashboardjobsearch") != -1
                  ? { backgroundColor: "#F0802B", color: "#FFFFFF" }
                  : null
              }
              className=" cursor-pointer font-semibold hover:justify-center px-[16px] sm:text-[22px] md:text-[24px] text-[26px] text-center text-gray_900 tracking-[-0.26px] w-[180px] rounded-[23px]"
            >
              Job Board
            </Button>
          </li>
          <li className="mt-[4px] mb-[6px] sm:w-[100%] sm:my-[10px] w-[auto]">
            <Button
              onClick={() => navigate("/dashboardapplication")}
              className=" cursor-pointer font-semibold hover:justify-center px-[16px] sm:text-[22px] md:text-[24px] text-[26px] text-center text-gray_900 tracking-[-0.26px] w-[180px] rounded-[23px]"
              style={
                urlSuffix.indexOf("dashboardapplication") != -1
                  ? { backgroundColor: "#F0802B", color: "#FFFFFF" }
                  : null
              }
            >
              Application
            </Button>
          </li>
          <li className="sm:w-[100%] sm:my-[10px] w-[auto]">
            <Img
              src="images/img_globe_gray_900.svg"
              className="h-[50px] w-[50px]"
              alt="globe"
            />
          </li>
        </ul>
        )}
      </header>
    </>
  );
};

DashboardProfileProfilenav.defaultProps = {
  cheetah: "Cheetah",
  profile: "Profile",
  settings: "Settings",
};

export default DashboardProfileProfilenav;
