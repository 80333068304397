import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ValidateEmailPage from "pages/Validateemail";
import ResendPage from "pages/Resend";
import RegisterendPage from "pages/RegisterEnd";
import DashboardjobsearchPage from "pages/Dashboardjobsearch";
import DashboardapplicationPage from "pages/Dashboardapplication";
const Dashboardsetting = React.lazy(() => import("pages/Dashboardsetting"));
const Dashboardprofile = React.lazy(() => import("pages/Dashboardprofile"));
const Surveyend = React.lazy(() => import("pages/Surveyend"));
const Surveyequal = React.lazy(() => import("pages/Surveyequal"));
const Surveywork = React.lazy(() => import("pages/Surveywork"));
const Surveyedu = React.lazy(() => import("pages/Surveyedu"));
const Surveycontactinfo = React.lazy(() => import("pages/Surveycontactinfo"));
const Surveyresume = React.lazy(() => import("pages/Surveyresume"));
const Resetpassword = React.lazy(() => import("pages/Resetpassword"));
const Forgotpassword = React.lazy(() => import("pages/Forgotpassword"));
const Login = React.lazy(() => import("pages/Login"));
const SignupOne = React.lazy(() => import("pages/SignupOne"));
const Home0310 = React.lazy(() => import("pages/Home0310"));
const Surveylink = React.lazy(() => import("pages/Surveylink"));
const ProjectRoutes = () => {
  return (
    <>
      <React.Suspense fallback={<></>}>
        <Router>
          <Routes>
            <Route path="/" element={<Home0310 />} />
            <Route path="/signup" element={<SignupOne />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<Forgotpassword />} />
            <Route path="/resetpassword" element={<Resetpassword />} />
            <Route path="/validate" element={<ValidateEmailPage />} />
            <Route path="/resendlink" element={<ResendPage/>} />
            <Route path="/surveyresume" element={<Surveyresume />} />
            <Route path="/surveycontactinfo" element={<Surveycontactinfo />} />
            <Route path="/surveyedu" element={<Surveyedu />} />
            <Route path="/surveywork" element={<Surveywork />} />
            <Route path="/surveyequal" element={<Surveyequal />} />
            <Route path="/surveylink" element={<Surveylink />} />
            <Route path="/surveyend" element={<Surveyend />} />
            <Route path="/dashboardprofile" element={<Dashboardprofile />} />
            <Route path="/dashboardsetting" element={<Dashboardsetting />} />
            <Route path="/registerend" element={<RegisterendPage />} />
            <Route path="/dashboardjobsearch" element={<DashboardjobsearchPage/>} ></Route>
            <Route path="/dashboardapplication" element={<DashboardapplicationPage/>}></Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </React.Suspense>
    </>
  );
};
export default ProjectRoutes;
