export const PageFooter = () => {
  return (
    <div className="flex sm:flex-col flex-row md:gap-[40px] items-center justify-between max-w-[1608px] mt-[10px] py-[16px] mx-[auto] md:px-[20px] w-[100%] px-[20px] h-[50px]">
      <a
        className="text-bluegray_400 text-left w-[auto]"
        style={{
          "fontStyle": "normal",
          "fontWeight": 400,
          "fontSize": "12px",
          "lineHeight": "150%",
          textDecoration: "none",
        }}
      >
        © 2023 CareerDog. All Rights Reserved.
      </a>
      <a
        className="text-bluegray_400 text-left w-[auto]"
        style={{
          "fontStyle": "normal",
          "fontWeight": 400,
          "fontSize": "12px",
          "lineHeight": "150%",
          textDecoration: "none",
        }}
      >
        Help
      </a>
      <a
        className="text-bluegray_400 text-left w-[auto]"
        style={{
          "fontStyle": "normal",
          "fontWeight": 400,
          "fontSize": "12px",
          "lineHeight": "150%",
          textDecoration: "none",
        }}
      >
        Terms of Use
      </a>
      <a
        className="text-bluegray_400 text-left w-[auto]"
        style={{
          "fontStyle": "normal",
          "fontWeight": 400,
          "fontSize": "12px",
          "lineHeight": "150%",
          textDecoration: "none",
        }}
      >
        Privacy Policies
      </a>
    </div>
  );
};
