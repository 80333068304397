import React, { useCallback, useEffect } from "react";

import DashboardProfileProfilenav from "components/DashboardProfileProfilenav";
import { useDispatch, useSelector } from "react-redux";
import { getInfo } from "slices/infoSlice";
import { PageFooter } from "components/PageFooter";
import { alpha, styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { getJobApply, getJobSearch } from "slices/jobSlice";
import axios from "axios";
import { joinPaths } from "pages/SignupOne";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Img } from "components";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "transparent",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "transparent",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
});

const Search = (props) => {
  const queryHistory = JSON.parse(localStorage.getItem("queryHistory"));
  const onClick = (e, query) => {
    if (query === "") {
      alert("info", "Please enter a query");
      return;
    } else {
      const queryHistoryList = localStorage.getItem("queryHistory");
      if (queryHistoryList === null) {
        localStorage.setItem("queryHistory", JSON.stringify([query]));
      } else {
        const queryHistory = JSON.parse(queryHistoryList);
        if (!queryHistory.includes(query)) {
          queryHistory.push(query);
          localStorage.setItem("queryHistory", JSON.stringify(queryHistory));
        } else {
          const idx = queryHistory.indexOf(query);
          queryHistory.splice(idx, 1);
          queryHistory.push(query);
          localStorage.setItem("queryHistory", JSON.stringify(queryHistory));
        }
      }
      window.location.href = `/dashboardjobsearch?query=${query}&sort=&filter=`;
    }
  };

  return (
    <div
      style={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 25px 12px 25px",
        width: "750px",
        height: "56px",
        background: "#FFFFFF",
        border: "2px solid #8C8C8C",
        borderRadius: "36px",
      }}
    >
      <Autocomplete
        freeSolo
        options={queryHistory ? queryHistory.reverse().slice(0, 10) : []}
        onInputChange={(e, v) => props.setInput(v)}
        inputValue={props.input}
        onChange={onClick}
        onKeyDown={(keyCode, event) => {
          if (keyCode === 13) {
            // Enter
            onClick(event, props.input);
          }
        }}
        style={{
          width: "100%",
          background: "transparent",
        }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "150%",
              letterSpacing: "0.02em",
              width: "100%",
            }}
          />
        )}
      />
      <div />
    </div>
  );
};

export const headCells = [
  { id: "Company", sortable: false, width: "260px" },
  { id: "Job Title", sortable: false, width: "520px" },
  { id: "Location", sortable: false, width: "240px" },
  { id: "Type", sortable: false, width: "240px" },
  {
    id: "Posted On",
    sortable: false,
    width: "140px",
    comparator: (a, b) => {
      // Date format: yyyy/mm/dd
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    },
  },
];

export const CustomizedTable = (props) => {
  const tableBodyCellStyles = {
    margin: "0 0",
    height: "24px",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "-0.005em",
  };

  const [rows, setRows] = React.useState(props.rows);

  const [order, setOrder] = React.useState([
    "asc",
    "asc",
    "asc",
    "asc",
    "asc",
    "asc",
  ]);

  const sort = (e) => {
    const idx = e.currentTarget.getAttribute("idx");
    const newOrder = [...order];
    newOrder[idx] = newOrder[idx] === "asc" ? "desc" : "asc";
    setOrder(newOrder);
    // Stable sort
    const newRows = [...rows];
    newRows.sort((a, b) => {
      const comparator = props.headCells[idx].comparator;
      if (comparator) {
        return newOrder[idx] === "asc" ? comparator(a, b) : comparator(b, a);
      }
      return a[props.headCells[idx].id].localeCompare(
        b[props.headCells[idx].id]
      );
    });
    setRows(newRows);
  };

  useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  return (
    <Table stickyHeader aria-label="sticky table" style={props.style}>
      {/* Table Head */}
      <TableHead>
        <TableRow>
          {props.headCells.map((headCell, idx) => (
            <TableCell
              key={headCell.id}
              align="left"
              style={{
                margin: "0 0",
                fontFamily: "'Poppins'",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "150%",
                letterSpacing: "-0.005em",
                color: "#1B1B1B",
                height: "56px",
                width: `${headCell.width}`,
              }}
              sortDirection={headCell.sortable ? order[idx] : false}
            >
              {headCell.sortable ? (
                <TableSortLabel idx={idx} direction={order[idx]} onClick={sort}>
                  {headCell.id}
                </TableSortLabel>
              ) : (
                headCell.id
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {/* Table Body */}
      <TableBody>
        {rows.length > 0 &&
          rows.map((row, index) => (
            <TableRow
              key={index}
              className={
                index % 2
                  ? "cursor-pointer hover:bg-gradient5 bg-white_A700 h-[56px]"
                  : "cursor-pointer hover:bg-gradient5 bg-gray_200 h-[56px]"
              }
              onClick={() => {
                const w = window.open("_black");
                w.location.href = joinPaths(
                  axios.defaults.baseURL,
                  `jobs/apply/?id=${row.id}&access=${localStorage.getItem(
                    "access"
                  )}`
                );
              }}
            >
              <TableCell key="site" style={tableBodyCellStyles}>
                {row.site}
              </TableCell>
              <TableCell key="name" style={tableBodyCellStyles}>
                {row.name}
              </TableCell>
              <TableCell key="location" style={tableBodyCellStyles}>
                {row.location}
              </TableCell>
              <TableCell key="type" style={tableBodyCellStyles}>
                {row.extra}
              </TableCell>
              <TableCell key="date" style={tableBodyCellStyles}>
                {row.date}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
      <TableCell
        colSpan={5}
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "24px",
          letterSpacing: "0.02em",
          textAlign: "center",
          width: "100%",
          color: "#C0BCB7",
          paddingLeft: "60px",
          borderBottom: "none",
        }}
      >
        {props.endFlag ? "--- End ---" : "--- Loading ---"}
      </TableCell>
    </Table>
  );
};

const DashboardjobsearchPage = () => {
  const dispatch = useDispatch();
  try {
    var currQuery =
      decodeURIComponent(window.location.href.split("?")[1]?.split("&")[0]?.split("=")[1] ?? "") ?? "";
  } catch (e) {
    var currQuery = window.location.href.split("?")[1]?.split("&")[0]?.split("=")[1] ?? "";
  }
  const [padding, setPadding] = React.useState((window.innerHeight - 222) / 2);
  const rows = useSelector((state) => state.job.searchResults);
  const status = useSelector((state) => state.job.status);
  const endFlag = useSelector((state) => state.job.endFlag);
  const [displayRows, setDisplayRows] = React.useState(rows);
  const [startFlag, setStartFlag] = React.useState(false); // 是否到达第一行
  const ref = React.useRef(null);
  const [input, setInput] = React.useState(currQuery);
  const estimatedCounts = useSelector((state) => state.job.estimatedCounts);
  const filter = window.location.href.split("?")[1]?.split("&")[2]?.split("=")[1] ?? "";
  const sort = window.location.href.split("?")[1]?.split("&")[1]?.split("=")[1] ?? "";
  const [imgSrc, setImgSrc] = React.useState('./images/img_scroll_top_default.svg');

  // 处理displayRows
  useEffect(() => {
    if (!startFlag) {
      setStartFlag(true);
    }
    setDisplayRows(rows);
  }, [rows, status]);

  useEffect(() => {
    const handleResize = () => {
      const padding = (window.innerHeight - 222) / 2;
      setPadding(padding);
    };
    window.addEventListener("resize", handleResize);
    dispatch(getInfo());
    if (currQuery != "") {
      // calculate begin_date
      const today = new Date();
      const begin_date = new Date();
      if (filter === "last_30_days") {
        begin_date.setDate(today.getDate() - 30);
      } else if (filter === "last_7_days") {
        begin_date.setDate(today.getDate() - 7);
      } 
      var begin_date_str;
      if (begin_date.getDate() < 10) {
        begin_date_str = `${begin_date.getFullYear()}-${begin_date.getMonth() + 1}-0${begin_date.getDate()}`;
      } else{
        begin_date_str = `${begin_date.getFullYear()}-${begin_date.getMonth() + 1}-${begin_date.getDate()}`;
      }
      if (filter === "") {
        dispatch(getJobSearch({ q: currQuery, offset: displayRows.length, sort: sort, begin_date: "" }));
      } else{
        dispatch(getJobSearch({ q: currQuery, offset: displayRows.length, sort: sort, begin_date: begin_date_str }));
      }
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleImg = () => {
    setImgSrc('./images/img_scroll_top_mousedown.svg');

    setTimeout(() => {
      setImgSrc('./images/img_scroll_top_default.svg');
    }, 100);
  }

  // 处理滚动条问题
  useEffect(() => {
    const styleTags = document.querySelectorAll("style");
    styleTags.forEach((styleTag) => {
      if (styleTag.textContent.includes("::-webkit-scrollbar")) {
        styleTag.parentNode.removeChild(styleTag);
      }
    });

    const handleBeforeUnload = () => {
      const styleTag = document.createElement("style");
      styleTag.textContent = `
            ::-webkit-scrollbar {
                display: none;
            }
            `;
      document.head.appendChild(styleTag);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      const styleTag = document.createElement("style");
      styleTag.textContent = `
            ::-webkit-scrollbar {
                display: none;
            }
            `;
      document.head.appendChild(styleTag);
    };
  }, []);

  // 处理滚动监听问题
  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    const handleScroll = () => {
      if (ref.current) {
        const { scrollTop, clientHeight, scrollHeight } = ref.current;
        if (scrollTop + clientHeight + 10 >= scrollHeight) {
          if (currQuery === "" || endFlag || status === "loading") {
            return;
          }
          // calculate begin_date
          const today = new Date();
          const begin_date = new Date();
          if (filter === "last_30_days") {
            begin_date.setDate(today.getDate() - 30);
          } else if (filter === "last_7_days") {
            begin_date.setDate(today.getDate() - 7);
          } 
          // 将日期转换成yyyy-mm-dd格式，确保是dd而不是d
          var begin_date_str;
          if (begin_date.getDate() < 10) {
            begin_date_str = `${begin_date.getFullYear()}-${begin_date.getMonth() + 1}-0${begin_date.getDate()}`;
          } else{
            begin_date_str = `${begin_date.getFullYear()}-${begin_date.getMonth() + 1}-${begin_date.getDate()}`;
          }
          if (filter === "") {
            dispatch(getJobSearch({ q: currQuery, offset: displayRows.length, sort: sort, begin_date: "" }));
          } else{
            dispatch(getJobSearch({ q: currQuery, offset: displayRows.length, sort: sort, begin_date: begin_date_str }));
          }
        }
      }
    };
    ref.current.addEventListener("scroll", handleScroll);
    return () => {
      if (ref.current != null) {
        ref.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ref, displayRows, endFlag, status]);

  return (
    <>
      <div className="flex flex-col font-poppins items-center justify-start mx-[auto] w-[100%] min-h-[100%] h-[auto]">
        <DashboardProfileProfilenav />
        <div
          className="h-[54px]"
          style={
            currQuery === ""
              ? { margin: `${padding}px 0` }
              : { margin: `16px 0` }
          }
        >
          <Search input={input} setInput={setInput} />
        </div>
        {currQuery != "" && (
          <>
            {
              <div
                style={{
                  display: "flex",
                  gap: "26px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "150%",
                  letterSpacing: "-0.5%",
                  padding: "20px 0",
                }}
              >
                <div>
                  <span style={{ color: "#ED8139" }}>
                    {String(estimatedCounts) === "undefined"
                      ? 0
                      : String(estimatedCounts)}
                    +
                  </span>{" "}
                  search result
                </div>
                <div style={{ color: "#B8B4BF" }}>|</div>
                <div>Filter:</div>
                <div
                  className="cursor-pointer underline"
                  style={{
                    color: filter === "last_30_days" ? "#F28B31" : "#000000",
                  }}
                  onClick={() => {
                    if (filter !== "last_30_days") {
                      window.location.href = `/dashboardjobsearch?query=${currQuery}&sort=${sort}&filter=last_30_days`;
                    } else {
                      window.location.href = `/dashboardjobsearch?query=${currQuery}&sort=${sort}&filter=`;
                    }
                  }}
                >
                  Last 30 days
                </div>
                <div
                  className="cursor-pointer underline"
                  style={{
                    color: filter === "last_7_days" ? "#F28B31" : "#000000",
                  }}
                  onClick={() => {
                    if (filter !== "last_7_days") {
                      window.location.href = `/dashboardjobsearch?query=${currQuery}&sort=${sort}&filter=last_7_days`;
                    } else {
                      window.location.href = `/dashboardjobsearch?query=${currQuery}&sort=${sort}&filter=`;
                    }
                  }}
                >
                  Last 7 days
                </div>
                <div style={{ color: "#B8B4BF" }}>|</div>
                <div>Sort by:</div>
                <div
                  className="cursor-pointer underline"
                  style={{ color: sort === "date" ? "#F28B31" : "#000000" }}
                  onClick={() => {
                    if (sort !== "date") {
                      window.location.href = `/dashboardjobsearch?query=${currQuery}&sort=date&filter=${filter}`;
                    }
                  }}
                >
                  Posted Date
                </div>
                <div
                  className="cursor-pointer underline"
                  style={{ color: sort === "" ? "#F28B31" : "#000000" }}
                  onClick={() => {
                    if (sort !== "") {
                      window.location.href = `/dashboardjobsearch?query=${currQuery}&sort=&filter=${filter}`;
                    }
                  }}
                >
                  Relevance
                </div>
              </div>
            }
            <TableContainer
              className="flex"
              ref={ref}
              style={{
                maxHeight: "calc(100vh - 316px)",
                padding: "0 60px 0 60px ",
                justifyContent: "center",
              }}
            >
              <CustomizedTable
                style={{
                  padding: "0 60px",
                  width: "100%",
                  height: "auto",
                  maxWidth: "1540px",
                  overflow: "scroll",
                }}
                headCells={headCells}
                rows={displayRows}
                endFlag={endFlag}
              />
            </TableContainer>
          </>
        )}
        {currQuery != "" && <div
          className="cursor-pointer underline"
          onClick={() => {
            ref.current?.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }} 
          style={{
            position: "absolute",
            bottom: "64px",
            right: "64px"
          }}
        >
          <Img
            src={imgSrc}
            onMouseOver={() =>setImgSrc('./images/img_scroll_top_hover.svg')}
            onMouseOut={() =>setImgSrc('./images/img_scroll_top_default.svg')}
            onClick={toggleImg}
          />
        </div>}
        <div
          style={{
            position: "absolute",
            width: "100%",
            bottom: 0,
            left: 0,
            right: 0,
            height: "60px",
          }}
        >
          <PageFooter />
        </div>
      </div>
    </>
  );
};

export default DashboardjobsearchPage;
