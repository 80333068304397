import axios from "axios";

axios.defaults.baseURL = "https://api.careerdog.ai/";
// axios.defaults.baseURL = "http://59.66.13.83:8555/"
// axios.defaults.withCredentials = true;

function reserve(email) {
  return axios.post("/reserve/", {
    username: email,
  });
}

function reserve_info() {
  return axios.get("/reserve/");
}

function register(email, password) {
  return axios.post("/auth/register/", {
    username: email,
    password: password,
  });
}

function login(email, password) {
  return axios.post("/auth/login/", {
    username: email,
    password: password,
  });
}

function forgot_password(email) {
  return axios.post("/auth/forget_password/", {
    username: email,
  });
}

function reset_password(token, password) {
  return axios.post("/auth/reset_password/", {
    password: password,
    token: token,
  });
}

function validate_email(token) {
  return axios.post("/auth/validate_email/", {
    token: token,
  });
}

function resend_email(email) {
  return axios.post("/auth/resend_email/", {
    username: email,
  });
}

function get_info() {
  return axios.get("/user/profile/", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access"),
    },
  });
}

function put_info(info) {
  return axios.put("/user/profile/", info, {
    headers: { Authorization: "Bearer " + localStorage.getItem("access") },
  });
}

function get_settings() {
  return axios.get("/user/settings/", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access"),
    },
  });
}

function put_settings(settings) {
  return axios.put("/user/settings/", settings, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access"),
    },
  });
}

function post_resumes(resume) {
  return axios.post("/user/resumes/", resume, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access"),
      'Content-Type': 'multipart/form-data'
    },
  });
}

function get_resumes() {
  return axios.get("/user/resumes/", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access"),
    },
  });
}

function get_one_resume(id) {
  return axios.get("/user/resumes/" + id + "/", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access"),
    },
  });
}

function delete_resumes(id) {
  return axios.delete("/user/resumes/" + id + "/", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access"),
    },
  });
}

function user_me() {
  return axios.get("/user/me/", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access"),
    },
  });
}

function get_parsed_info(id) {
  return axios.post("/survey_fit/",
    { "resume": id },
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access"),
      }
    }
  );
}

function get_extension() {
  return axios.get("/get_extension/", {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access"),
    },
  });
}

function get_job_search(q, offset, begin_date, sort) {
  return axios.get(`/jobs/search/?q=${q}&offset=${offset}&limit=20&sort=${sort}&begin_date=${begin_date}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access"),
      }
    })
}

function get_job_history(offset) {
  return axios.get(`/jobs/history/?offset=${offset}&limit=20`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access"),
      }
    })
}

export async function handleRequest(action) {
  try {
    return await action();
  } catch (err) {
    if (err.response?.data?.err_msg) {
      throw {
        message:
          "Status code " +
          err.response.status +
          ": " +
          err.response.data.err_msg,
      };
    } else {
      throw err;
    }
  }
}

export default {
  reserve,
  reserve_info,
  register,
  login,
  forgot_password,
  reset_password,
  get_info,
  put_info,
  get_settings,
  put_settings,
  validate_email,
  resend_email,
  post_resumes,
  get_resumes,
  get_one_resume,
  delete_resumes,
  user_me,
  get_parsed_info,
  get_extension,
  get_job_search,
  get_job_history
};
