import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyEdu } from "pages/Surveyedu/index.jsx";
import { emptyWork } from "pages/Surveywork/index.jsx";
import API, { handleRequest } from "../API.js";

export const putInfo = createAsyncThunk("putInfo", async (info) => {
  return await handleRequest(async () => {
    const response = await API.put_info(info);
    return response.data;
  });
});

export const putSlientInfo = createAsyncThunk("putSlientInfo", async (info) => {
  return await handleRequest(async () => {
    const response = await API.put_info(info);
    return response.data; 
  });
});

export const getInfo = createAsyncThunk("getInfo", async () => {
  return await handleRequest(async () => {
    const response = await API.get_info();
    return response.data;
  });
});

export const getSettings = createAsyncThunk("getSettings", async () => {
  return await handleRequest(async () => {
    const response = await API.get_settings();
    return response.data;
  });
});

export const putSettings = createAsyncThunk("putSettings", async (settings) => {
  return await handleRequest(async () => {
    const response = await API.put_settings(settings);
    return response.data;
  });
});

export const postResumes = createAsyncThunk("postResumes", async (resume) => {
  return await handleRequest(async () => {
    const response = await API.post_resumes(resume);
    return response.data;
  });
});

export const getOneResume = createAsyncThunk("getOneResume", async (id) => {
  return await handleRequest(async () => {
    const response = await API.get_one_resume(id);
    return response.data;
  });
});

export const getResumes = createAsyncThunk("getResumes", async () => {
  return await handleRequest(async () => {
    const response = await API.get_resumes();
    return response.data;
  });
});

export const deleteResumes = createAsyncThunk("deleteResumes", async (id) => {
  return await handleRequest(async () => {
    const response = await API.delete_resumes(id);
    return response.data;
  });
});

export const userMe = createAsyncThunk("userMe", async () => {
  return await handleRequest(async () => {
    const response = await API.user_me();
    return response.data;
  });
});

export const getParsedInfo = createAsyncThunk("getParsedInfo", async (id) => {
  return await handleRequest(async () => {
    const response = await API.get_parsed_info(id);
    return response.data;
  });
});

export const getExtension = createAsyncThunk("getExtension", async () => {
  return await handleRequest(async () => {
    const response = await API.get_extension();
    return response.data;
  });
});

export function handleErrors(action) {
  if (action.error?.message.indexOf("401") !== -1) {
    relogin();
  } else {
    setTimeout(() => {
      alert(
        "error",
        action.error?.message ?? "Failed to get your information!"
      );
    }, 100);
  }
}

export function relogin() {
  localStorage.removeItem("access");
  localStorage.removeItem("queryHistory")
  localStorage.setItem("open", true);
  localStorage.setItem(
    "message",
    "Your session has expired. Please log in again."
  );
  localStorage.setItem("type", "info");
  window.location.href = "/login";
}

export const infoSlice = createSlice({
  name: "info",
  initialState: {
    status: "idle",
    upload_status: "idle",
    upload_id: -1,
    upload_parsed: false,
    delete_status: "idle",
    get_parsed_status: "idle",
    email: "",
    parsed_info: {
    },
    info: {
      contact_info: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        city: "",
        location: "",
        country: "",
        postal_code: "",
      },
      education: [emptyEdu],
      work_experience: [emptyWork],
      privacy_info: {
        race: "",
        nationality: "",
        gender: "",
        gender_identity: "",
        sex_orientation: "",
        veteran: -1,
        sponsorship: -1,
        disability: "",
      },
      link: {
        primary: "",
        others: [""],
      },
    },
    settings: {
      profile: [],
      job_level: [],
      interests: [],
      notification: [],
    },
    resumes: [],
  },
  extraReducers(builder) {
    builder
      .addCase(putInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.info = action.payload;
        setTimeout(() => {
          alert("success", "Your information has been updated!");
        }, 100);
      })
      .addCase(putInfo.rejected, (state, action) => {
        state.status = "failed";
        if (action.error?.message?.indexOf("401") !== -1) {
          relogin();
        } else {
          setTimeout(() => {
            alert(
              "error",
              action.error?.message ?? "Failed to update your information!"
            );
          }, 100);
        }
      })
      .addCase(putSlientInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putSlientInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.info = action.payload;
      })
      .addCase(putSlientInfo.rejected, (state, action) => {
        state.status = "failed";
        if (action.error?.message?.indexOf("401") !== -1) {
          relogin();
        } else {
          setTimeout(() => {
            alert(
              "error",
              action.error?.message ?? "Failed to update your information!"
            );
          }, 100);
        }
      })
      .addCase(getInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.info = action.payload;
        if (
          action.payload.updated === false && window.location.pathname !== "/registerend" 
          && window.location.pathname !== "/" && window.location.pathname.indexOf("/survey") === -1
        ) {
          window.location.href = "/registerend";
        }
      })
      .addCase(getInfo.rejected, (state, action) => {
        state.status = "failed";
        handleErrors(action);
      })
      .addCase(getSettings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.settings = action.payload.settings;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.status = "failed";
        handleErrors(action);
      })
      .addCase(putSettings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putSettings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.settings = action.payload.settings;
      })
      .addCase(putSettings.rejected, (state, err) => {
        state.status = "failed";
        handleErrors(err);
      })
      .addCase(postResumes.pending, (state) => {
        state.upload_status = "loading";
        state.upload_id = -1;
        state.upload_parsed = false;
      })
      .addCase(postResumes.fulfilled, (state, action) => {
        state.upload_status = "succeeded";
        state.upload_id = action.payload.id;
        state.upload_parsed = false;
      })
      .addCase(postResumes.rejected, (state, err) => {
        state.upload_status = "failed";
        state.upload_id = -1;
        handleErrors(err);
      })
      .addCase(getResumes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getResumes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.resumes = action.payload;
      })
      .addCase(getResumes.rejected, (state, err) => {
        state.status = "failed";
        handleErrors(err);
      })
      .addCase(getOneResume.fulfilled, (state, action) => {
        state.upload_parsed = action.payload.parsed;
      })
      .addCase(getOneResume.rejected, (state, err) => {
        handleErrors(err);
      })
      .addCase(deleteResumes.pending, (state) => {
        state.delete_status = "loading";
        state.upload_status = "idle";
      })
      .addCase(deleteResumes.fulfilled, (state, action) => {
        state.delete_status = "succeeded";
      })
      .addCase(deleteResumes.rejected, (state, err) => {
        state.delete_status = "failed";
        handleErrors(err);
      })
      .addCase(userMe.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userMe.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.email = action.payload.username;
      })
      .addCase(userMe.rejected, (state, action) => {
        state.status = "failed";
        if (action.error?.message.indexOf("401") !== -1) {
          if(localStorage.getItem("access") !== null)
            relogin();
          else {
          }
        } else {
          setTimeout(() => {
            alert(
              "error",
              action.error?.message ?? "Failed to get your information!"
            );
          }, 100);
        }
        state.email = "";
      })
      .addCase(getParsedInfo.pending, (state) => {
        state.get_parsed_status = "loading";
      })
      .addCase(getParsedInfo.fulfilled, (state, action) => {
        state.get_parsed_status = "succeeded";
        state.parsed_info = action.payload;
        for (let key in state.parsed_info) {
          if (state.parsed_info[key] === "N/A") {
            state.parsed_info[key] = undefined;
          }
        }
        setTimeout(() => {
          alert("success", "Auto fill your information!");
        }, 100);
      })
      .addCase(getParsedInfo.rejected, (state, err) => {
        state.get_parsed_status = "failed";
        handleErrors(err);
      })
      .addCase(getExtension.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getExtension.fulfilled, (state, action) => {
        state.status = "succeeded";
        window.location.href = action.payload.url;
      })
      .addCase(getExtension.rejected, (state, err) => {
        state.status = "failed";
        handleErrors(err);
      })
  },
});

export default infoSlice.reducer;
