import React, { useDebugValue, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userRegister, userResendEmail } from "slices/userSlice";
import { Text, Button, Img, Line } from "components";
import SignupOneColumnemail from "components/SignupOneColumnemail";
import PluginFileCoverOneLogo from "components/PluginFileCoverOneLogo";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { useGoogleLogin } from "@react-oauth/google";
export function joinPaths(path1, path2) {
  if (path1.endsWith("/")) {
    return path1 + path2;
  } else {
    return path1 + "/" + path2;
  }
}

const SignupOnePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [firstVisible, setFirstVisible] = React.useState(false);
  const [secondVisible, setSecondVisible] = React.useState(false);
  const { status, message } = useSelector((state) => state.user);

  const handleFirstVisible = () => {
    setFirstVisible(!firstVisible);
  };

  const handleSecondVisible = () => {
    setSecondVisible(!secondVisible);
  };

  const [currentStatus, setCurrentStatus] = React.useState("idle");

  useEffect(() => {
    if (currentStatus != "others") {
      setCurrentStatus(status);
      if (status === "succeeded") {
        navigate("/resendlink?email=" + email);
      }
    } else {
      setCurrentStatus("idle");
    }
  }, [status]);

  const handleRegister = () => {
    if (email.indexOf("@") === -1 || email === "") {
      alert("info", "Please enter a valid email address");
    } else if (password === "" || password.length < 8) {
      alert("info", "Password must be at least 8 characters");
    } else if (currentPassword !== password) {
      alert("info", "Passwords do not match");
    } else {
      dispatch(userRegister({ email, password }));
    }
  };

  return (
    <>
      <div
        className="bg-white_A700 flex sm:flex-col md:flex-col flex-row font-poppins sm:gap-[40px] md:gap-[40px] h-[100%] items-start justify-start mx-[auto] sm:pl-[20px] md:pl-[40px] self-stretch sm:w-[100%] md:w-[100%] w-[auto]"
        style={{ overflow: "hidden" }}
      >
        <div className="flex flex-col h-[100%] items-center justify-between w-[60%] px-[15%] py-[1%]">
          <div className="flex items-center justify-start md:w-[100%] w-[auto]">
            <a
              className="font-normal not-italic text-[12px] text-bluegray_400 text-left underline w-[auto] cursor-pointer"
              onClick={() => navigate("/")}
            >
              Back to homepage
            </a>
          </div>
          <div
            className="flex h-[100%] md:h-[auto] items-center justify-start sm:w-[100%] w-[auto]"
          >
            <div className="flex flex-col gap-[26px] items-start justify-center self-stretch w-[auto]">
              <div className="flex flex-col font-poppins gap-[3px] items-start justify-start self-stretch w-[auto]">
                <Text
                  className="text-center text-gray_900 tracking-[-0.72px] w-[auto]"
                  as="h3"
                  variant="h3"
                >
                  Sign Up
                </Text>
                <Text
                  className="not-italic text-bluegray_400 text-left w-[auto]"
                  variant="body7"
                >
                  Enter your email and password to sign in!
                </Text>
              </div>
              <Button
                className="common-pointer bg-gray_50 cursor-pointer flex items-center justify-center sm:min-w-[100%] min-w-[411px] px-[127px] py-[14px] rounded-[8px] w-[auto]"
                leftIcon={
                  <Img
                    src="images/img_google.svg"
                    className="mb-[1px] mr-[9px]"
                    alt="google"
                  />
                }
                onClick={() => {
                  window.location.href =
                    joinPaths(axios.defaults.baseURL, "auth/google/");
                }}
              >
                <div className="font-dmsans font-medium md:px-[40px] sm:px-[20px] text-[14px] text-gray_900 text-left tracking-[-0.28px]">
                  Sign up with Google
                </div>
              </Button>
              <div className="flex sm:flex-col flex-row font-dmsans gap-[16px] items-center justify-start self-stretch sm:w-[100%] w-[auto]">
                <Line className="bg-gray_401 h-[1px] w-[45%]" />
                <Text
                  className="font-medium text-bluegray_400 text-center tracking-[-0.28px] w-[auto]"
                  variant="body6"
                >
                  or{" "}
                </Text>
                <Line className="bg-gray_401 h-[1px] w-[45%]" />
              </div>
              <div className="flex flex-col gap-[16px] items-start justify-start self-stretch sm:w-[100%] w-[auto]">
                <div className="flex font-poppins items-start justify-start self-stretch sm:w-[100%] w-[auto]">
                  <SignupOneColumnemail
                    className="flex flex-col items-start justify-start self-stretch sm:w-[100%] w-[auto]"
                    email="Email"
                    // defaultValue="email@gmail.com"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="flex font-poppins items-start justify-start self-stretch sm:w-[100%] w-[auto]">
                  <SignupOneColumnemail
                    className="flex flex-col items-start justify-start self-stretch sm:w-[100%] w-[auto]"
                    email="Password"
                    // defaultValue="Min.8 characters"
                    type={firstVisible ? "text" : "password"}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    suffix={
                      <Img
                        src={
                          firstVisible
                            ? "images/img_eye.svg"
                            : "images/img_no_eye.svg"
                        }
                        alt="eye"
                        className="w-[20px] pt-[8px] cursor-pointer"
                        onClick={handleFirstVisible}
                      />
                    }
                  />
                </div>
                <div className="flex font-poppins items-start justify-start self-stretch sm:w-[100%] w-[auto]">
                  <SignupOneColumnemail
                    className="flex flex-col items-start justify-start self-stretch sm:w-[100%] w-[auto]"
                    email="Confirm Password"
                    // defaultValue="and try not top forget"
                    type={secondVisible ? "text" : "password"}
                    value={currentPassword}
                    onChange={(e) => {
                      setCurrentPassword(e.target.value);
                    }}
                    suffix={
                      <Img
                        src={
                          secondVisible
                            ? "images/img_eye.svg"
                            : "images/img_no_eye.svg"
                        }
                        alt="eye"
                        className="w-[20px] pt-[8px] cursor-pointer"
                        onClick={handleSecondVisible}
                      />
                    }
                  />
                </div>
                <Text
                  className="common-pointer font-bold font-dmsans text-gray_900 text-left tracking-[-0.28px] w-[auto]"
                  variant="body6"
                  onClick={() => navigate("/login")}
                >
                  <span className="text-gray_900 text-[14px] font-normal not-italic">
                    Already have an account?
                  </span>
                  <span className="text-gray_900 text-[14px]"> </span>
                  <a className="text-yellow_900 text-[14px] underline">
                    Sign In
                  </a>
                </Text>
                <a
                  className="common-pointer font-bold font-dmsans text-[14px] text-left text-yellow_900 tracking-[-0.28px] underline w-[auto]"
                  onClick={() => navigate("/resendlink")}
                >
                  Resend Verification Email
                </a>
              </div>
              {currentStatus === "loading" ? (
                <Button
                  className="bg-gray_900 cursor-pointer font-semibold sm:px-[20px] px-[26px] py-[12px] rounded-[8px] sm:text-[22px] md:text-[24px] text-[26px] text-center text-white_A700 tracking-[-0.26px] w-[410px]"
                  style={{ backgroundColor: "#8C8C8C" }}
                  disabled="disabled"
                >
                  Please wait... &nbsp;&nbsp;{" "}
                  <Img src="images/cd-logo-white-46.svg" />
                </Button>
              ) : currentStatus === "succeeded" ? (
                <>
                  <Button
                    className="bg-gray_900 cursor-pointer font-semibold sm:px-[20px] px-[26px] py-[12px] rounded-[8px] sm:text-[22px] md:text-[24px] text-[26px] text-center text-white_A700 tracking-[-0.26px] w-[410px]"
                    style={{ backgroundColor: "#8C8C8C" }}
                    disabled="disabled"
                  >
                    <Img src="images/img_check_circle.svg" /> &nbsp; Email Sent.
                    &nbsp;&nbsp; <Img src="images/cd-logo-white-46.svg" />
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => {
                    handleRegister();
                  }}
                  className="bg-gray_900 cursor-pointer font-semibold sm:px-[20px] px-[26px] py-[12px] rounded-[8px] shadow-bs sm:text-[22px] md:text-[24px] text-[26px] text-center text-white_A700 tracking-[-0.26px] w-[410px]"
                >
                  Let 's Go!
                  &nbsp;&nbsp;&nbsp;
                  <Img src="images/cd-logo-white-46.svg" />
                </Button>
              )}
            </div>
          </div>
          <Text
            className="font-normal not-italic text-gray_401 text-left tracking-[0.32px] w-[auto]"
            variant="body3"
          >
            © 2023 CareerDog. All Rights Reserved.{" "}
          </Text>
        </div>
        <div className="h-[100%] relative md:w-[100%] w-[55%]">
          <Img
            src="images/img_background54.png"
            className="h-[100%] m-[auto] object-cover rounded-bl-[200px] rounded-br-[0] rounded-tl-[0] rounded-tr-[0] w-[100%]"
            alt="backgroundFiftyFour"
          />
          <PluginFileCoverOneLogo
            color="white"
            className=" absolute flex  flex-col inset-x-[0] items-center justify-start mx-[auto] sm:px-[20px] px-[21px] rounded-bl-[200px] rounded-br-[0] rounded-tl-[0] rounded-tr-[0] top-[40%] sm:w-[100%] w-[auto] text-white_A700"
            cheetah="CareerDog"
          />
        </div>
      </div>
    </>
  );
};

export default SignupOnePage;
