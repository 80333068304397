import React, {useEffect} from "react";

import { Button, Img, Text } from "components";

const DatePicker = (props) => {
  const [displayYear, setDisplayYear] = React.useState(props.currentYear);
  
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (DatePicker.current && !DatePicker.current.contains(event.target)) {
      props.onCancel();
    }
  }

  return (
    <>
      <div className={props.className}>
        <div className="flex items-start justify-start w-[auto]">
          <div className="flex flex-row items-center justify-between p-[16px] w-[100%]">
            <Button
              onClick={() => {
                setDisplayYear(displayYear - 1);
              }}
              className="border-[1px] border-gray_400 border-solid flex h-[40px] items-center justify-center p-[11px] rounded-[8px] shadow-bs1 w-[40px]"
            >
              <Img
                src="images/img_arrowleft.svg"
                className="h-[18px]"
                alt="arrowleft"
              />
            </Button>
            <div className="flex items-start justify-start self-stretch w-[auto]">
              {/* <input
                className="font-poppins text-center text-gray_900 w-[auto]"
                as="h6"
                value={displayYear}
                onChange={(e) => {
                  setDisplayYear(e.target.value);
                }}
                variant="h6"
              />
              */}
              <Text
                className="font-poppins text-center text-gray_900 w-[auto]"
                as="h6"
                variant="h6"
              >
                {displayYear}
              </Text>
            </div>
            <Button
              onClick={() => {
                setDisplayYear(displayYear + 1);
              }}
              className="border-[1px] border-gray_400 border-solid flex h-[40px] items-center justify-center p-[11px] rounded-[8px] shadow-bs1 w-[40px]"
            >
              <Img
                src="images/img_arrowright.svg"
                className="h-[18px]"
                alt="arrowright"
              />
            </Button>
          </div>
        </div>
        <div className="flex items-start justify-start px-[16px] self-stretch md:w-[100%] w-[auto]">
          <div className="flex items-start justify-start self-stretch w-[auto]">
            <div className="flex items-center justify-start w-[100%]">
              <div className="flex flex-col gap-[8px] items-center justify-start w-[100%]">
                <div className="flex flex-row gap-[12px] items-center justify-between w-[100%]">
                  <Button
                    className="cursor-pointer font-poppins font-semibold datebutton px-[12px] py-[8px] rounded-[8px]  text-[16px] text-center  tracking-[-0.08px] w-[90px]"
                    style={
                      (props.currentYear === displayYear) & ("Jan" === props.currentMonth)
                        ? { backgroundColor: "#f28b31", color: "#FFFFFF" }
                        : null
                    }
                    onClick={() => {
                      if (props.currentYear === displayYear && props.currentMonth === "Jan") {
                        props.setCurrentMonth("");
                      } else {
                        props.setCurrentYear(displayYear);
                        props.setCurrentMonth("Jan");
                      }
                    }}
                  >
                    01/Jan
                  </Button>
                  <Button
                    className="cursor-pointer font-poppins font-semibold datebutton px-[12px] py-[8px] rounded-[8px]  text-[16px] text-center  tracking-[-0.08px] w-[90px]"
                    style={
                      (props.currentYear === displayYear) & ("Feb" === props.currentMonth)
                        ? { backgroundColor: "#f28b31", color: "#FFFFFF" }
                        : null
                    }
                    onClick={() => {
                      if (props.currentYear === displayYear && props.currentMonth === "Feb") {
                        props.setCurrentMonth("");
                      } else {
                        props.setCurrentYear(displayYear);
                        props.setCurrentMonth("Feb");
                      }
                    }}
                  >
                    02/Feb
                  </Button>
                  <Button
                    className="cursor-pointer font-poppins font-semibold datebutton px-[12px] py-[8px] rounded-[8px]  text-[16px] text-center  tracking-[-0.08px] w-[90px]"
                    style={
                      (props.currentYear === displayYear) & ("Mar" === props.currentMonth)
                        ? { backgroundColor: "#f28b31", color: "#FFFFFF" }
                        : null
                    }
                    onClick={() => {
                      if (props.currentYear === displayYear && props.currentMonth === "Mar") {
                        props.setCurrentMonth("");
                      } else {
                        props.setCurrentYear(displayYear);
                        props.setCurrentMonth("Mar");
                      }
                    }}
                  >
                    03/Mar
                  </Button>
                </div>
                <div className="flex flex-row gap-[12px] items-center justify-between w-[100%]">
                  <Button
                    className="cursor-pointer font-poppins font-semibold datebutton px-[12px] py-[8px] rounded-[8px]  text-[16px] text-center  tracking-[-0.08px] w-[90px]"
                    style={
                      (props.currentYear === displayYear) & ("Apr" === props.currentMonth)
                        ? { backgroundColor: "#f28b31", color: "#FFFFFF" }
                        : null
                    }
                    onClick={() => {
                      if (props.currentYear === displayYear && props.currentMonth === "Apr") {
                        props.setCurrentMonth("");
                      } else {
                        props.setCurrentYear(displayYear);
                        props.setCurrentMonth("Apr");
                      }
                    }}
                  >
                    04/Apr
                  </Button>
                  <Button
                    className="cursor-pointer font-poppins font-semibold datebutton px-[12px] py-[8px] rounded-[8px]  text-[16px] text-center  tracking-[-0.08px] w-[90px]"
                    style={
                      (props.currentYear === displayYear) & ("May" === props.currentMonth)
                        ? { backgroundColor: "#f28b31", color: "#FFFFFF" }
                        : null
                    }
                    onClick={() => {
                      if (props.currentYear === displayYear && props.currentMonth === "May") {
                        props.setCurrentMonth("");
                      } else {
                        props.setCurrentYear(displayYear);
                        props.setCurrentMonth("May");
                      }
                    }}
                  >
                    05/May
                  </Button>
                  <Button
                    className="cursor-pointer font-poppins font-semibold datebutton px-[12px] py-[8px] rounded-[8px]  text-[16px] text-center  tracking-[-0.08px] w-[90px]"
                    style={
                      (props.currentYear === displayYear) & ("Jun" === props.currentMonth)
                        ? { backgroundColor: "#f28b31", color: "#FFFFFF" }
                        : null
                    }
                    onClick={() => {
                      if (props.currentYear === displayYear && props.currentMonth === "Jun") {
                        props.setCurrentMonth("");
                      } else {
                        props.setCurrentYear(displayYear);
                        props.setCurrentMonth("Jun");
                      }
                    }}
                  >
                    06/Jun
                  </Button>
                </div>
                <div className="flex flex-row gap-[12px] items-center justify-between w-[100%]">
                  <Button
                    className="cursor-pointer font-poppins font-semibold datebutton px-[12px] py-[8px] rounded-[8px]  text-[16px] text-center  tracking-[-0.08px] w-[90px]"
                    style={
                      (props.currentYear === displayYear) & ("Jul" === props.currentMonth)
                        ? { backgroundColor: "#f28b31", color: "#FFFFFF" }
                        : null
                    }
                    onClick={() => {
                      if (props.currentYear === displayYear && props.currentMonth === "Jul") {
                        props.setCurrentMonth("");
                      } else {
                        props.setCurrentYear(displayYear);
                        props.setCurrentMonth("Jul");
                      }
                    }}
                  >
                    07/Jul
                  </Button>
                  <Button
                    className="cursor-pointer font-poppins font-semibold datebutton px-[12px] py-[8px] rounded-[8px]  text-[16px] text-center  tracking-[-0.08px] w-[90px]"
                    style={
                      (props.currentYear === displayYear) & ("Aug" === props.currentMonth)
                        ? { backgroundColor: "#f28b31", color: "#FFFFFF" }
                        : null
                    }
                    onClick={() => {
                      if (props.currentYear === displayYear && props.currentMonth === "Aug") {
                        props.setCurrentMonth("");
                      } else {
                        props.setCurrentYear(displayYear);
                        props.setCurrentMonth("Aug");
                      }
                    }}
                  >
                    08/Aug
                  </Button>
                  <Button
                    className="cursor-pointer font-poppins font-semibold datebutton px-[12px] py-[8px] rounded-[8px]  text-[16px] text-center  tracking-[-0.08px] w-[90px]"
                    style={
                      (props.currentYear === displayYear) & ("Sep" === props.currentMonth)
                        ? { backgroundColor: "#f28b31", color: "#FFFFFF" }
                        : null
                    }
                    onClick={() => {
                      if (props.currentYear === displayYear && props.currentMonth === "Sep") {
                        props.setCurrentMonth("");
                      } else {
                        props.setCurrentYear(displayYear);
                        props.setCurrentMonth("Sep");
                      }
                    }}
                  >
                    09/Sep
                  </Button>
                </div>
                <div className="flex flex-row gap-[12px] items-center justify-between w-[100%]">
                  <Button
                    className="cursor-pointer font-poppins font-semibold datebutton px-[12px] py-[8px] rounded-[8px]  text-[16px] text-center  tracking-[-0.08px] w-[90px]"
                    style={
                      (props.currentYear === displayYear) & ("Oct" === props.currentMonth)
                        ? { backgroundColor: "#f28b31", color: "#FFFFFF" }
                        : null
                    }
                    onClick={() => {
                      if (props.currentYear === displayYear && props.currentMonth === "Oct") {
                        props.setCurrentMonth("");
                      } else {
                        props.setCurrentYear(displayYear);
                        props.setCurrentMonth("Oct");
                      }
                    }}
                  >
                    10/Oct
                  </Button>
                  <Button
                    className="cursor-pointer font-poppins font-semibold datebutton px-[12px] py-[8px] rounded-[8px]  text-[16px] text-center  tracking-[-0.08px] w-[90px]"
                    style={
                      (props.currentYear === displayYear) & ("Nov" === props.currentMonth)
                        ? { backgroundColor: "#f28b31", color: "#FFFFFF" }
                        : null
                    }
                    onClick={() => {
                      if (props.currentYear === displayYear && props.currentMonth === "Nov") {
                        props.setCurrentMonth("");
                      } else {
                        props.setCurrentYear(displayYear);
                        props.setCurrentMonth("Nov");
                      }
                    }}
                  >
                    11/Nov
                  </Button>
                  <Button
                    className="cursor-pointer font-poppins font-semibold datebutton px-[12px] py-[8px] rounded-[8px]  text-[16px] text-center  tracking-[-0.08px] w-[90px]"
                    style={
                      (props.currentYear === displayYear) & ("Dec" === props.currentMonth)
                        ? { backgroundColor: "#f28b31", color: "#FFFFFF" }
                        : null
                    }
                    onClick={() => {
                      if (props.currentYear === displayYear && props.currentMonth === "Dec") {
                        props.setCurrentMonth("");
                      } else {
                        props.setCurrentYear(displayYear);
                        props.setCurrentMonth("Dec");
                      }
                    }}
                  >
                    12/Dec
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-[16px] items-center justify-between p-[16px] w-[100%]">
          <div className="flex items-center justify-start self-stretch w-[auto]">
            <div className="flex items-start justify-start w-[128px]">
              <Text
                className="bg-white_A700 border-[1px] border-gray_400 border-solid font-light font-poppins px-[14px] py-[12px] rounded-[8px] text-gray_900 text-left w-[100%]"
                style={{"fontSize":"18px"}}
                as="h6"
                variant="h6"
              >
                {props.currentMonth ? props.currentMonth + " " + props.currentYear : "Pick a date"}
              </Text>
            </div>
          </div>
          <div className="flex items-center justify-start self-stretch w-[auto]">
            <Button
              onClick={props.onClose}
              className="bg-yellow_900 cursor-pointer font-poppins font-semibold px-[14px] py-[10px] rounded-[8px] shadow-bs1 text-[16px] text-center text-white_A700 tracking-[-0.08px] w-[121px]">
              Set Date
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatePicker;
