import React from "react";

import { Img, Text } from "components";

const SurveyResumeSurveycard = (props) => {
  return (
    <>
      <div className={props.className}   >
        <div >
          {!!props?.reply ? (
            <Img
              src={props?.reply}
              className="h-[84px] w-[100px]"
              alt="reply"
            />
          ) : null}
          {!!props?.dragyourresume ? (
            <Text
              className="font-light font-poppins leading-[150.00%] md:max-w-[100%] max-w-[320px] text-bluegray_400 text-center tracking-[0.52px]"
              as="h5"
              variant="h5"
            >
              {props?.dragyourresume}
            </Text>
          ) : null}
        </div>
        <div className="flex flex-row sm:gap-[40px] items-start justify-between w-[100%]">
          <div className="flex items-center justify-start w-[auto]">
            {!!props?.previous ? (
              <a
                className="font-poppins font-semibold sm:text-[32px] md:text-[34px] text-[36px] text-gray_401 text-left tracking-[-0.72px] underline w-[auto]"
                href=""
              >
                {props?.previous}
              </a>
            ) : null}
          </div>
          <div className="flex items-center justify-start pt-[3px] w-[auto]">
            {!!props?.skip ? (
              <a
                className="font-poppins font-semibold sm:text-[32px] md:text-[34px] text-[36px] text-gray_900 text-left tracking-[-0.72px] underline w-[auto]"
                href=""
              >
                {props?.skip}
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

SurveyResumeSurveycard.defaultProps = {};

export default SurveyResumeSurveycard;
