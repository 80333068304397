import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { handleRequest } from "../API.js";

export const getJobSearch = createAsyncThunk(
    "getJobSearch",
    async (params) => {
        return await handleRequest(async () => {
        const response = await API.get_job_search(params.q, params.offset, params.begin_date, params.sort);
        return response.data;
        });
    }
)

export const getJobHistory = createAsyncThunk(
    "getJobHistory",
    async (params) => {
        return await handleRequest(async () => {
        const response = await API.get_job_history(params.offset);
        return response.data;
        });
    }
)

export const jobSlice = createSlice({
    name: "job",
    initialState: {
        searchResults: [],
        applications: [],
        status: "idle",
        endFlag: false,
        estimatedTotalHits: -1,
    },
    extraReducers: {
        [getJobHistory.pending]: (state) => {
            state.status = "loading";
        },
        [getJobSearch.fulfilled]: (state, action) => {
            if (!action.payload.jobs || action.payload.jobs.length < action.payload.limit) {
                state.endFlag = true;
            }
            if (action.payload.offset == state.searchResults.length && action.payload.jobs) {
                state.searchResults = state.searchResults.concat(action.payload.jobs);
            }
            state.status = "succeeded";
            state.estimatedCounts = action.payload.estimatedTotalHits;
        },
        [getJobSearch.rejected]: (state, action) => {
            state.status = "failed";
            setTimeout(() => {
                alert("error", action?.error?.message ?? "Something went wrong!");
              }, 100);
        },
        [getJobHistory.pending]: (state) => {
            state.status = "loading";
        },
        [getJobHistory.fulfilled]: (state, action) => {
            if (!action.payload.jobs || action.payload.jobs.length < action.payload.limit) {
                state.endFlag = true;
            }
            if (action.payload.offset == state.applications.length && action.payload.jobs) {
                state.applications = state.applications.concat(action.payload.jobs);
            }
            state.status = "succeeded";
        },
        [getJobHistory.rejected]: (state, action) => {
            state.status = "failed";
            setTimeout(() => {
                alert("error", action?.error?.message ?? "Something went wrong!");
              }, 100);
        },
    },
});

export default jobSlice.reducer;