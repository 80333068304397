import React from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "components";

const PluginFileCoverOneLogo = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={props.className}>
        <Text
          className="text-white_A700 mt-[0%] gocheetah nav-menu-item font-poppins font-semibold italic tracking-[-0.64px] w-[auto] cursor-pointer h-[100%]"
          style={{ "color": props.color }}
          as="h3"
          variant="h3"
        >
          <a className="h-[100%]" onClick={() => navigate("/")} style={{"textDecoration": "none", "userSelect":"none"}}>
            {props?.cheetah}
          </a>
        </Text>
      </div>
    </>
  );
};

PluginFileCoverOneLogo.defaultProps = { cheetah: "Cheetah" };

export default PluginFileCoverOneLogo;
