import { CustomizedSnackbars } from "components/Alert";
import { Provider, useSelector } from "react-redux";
import Routes from "./Routes";
import store, { closeAlert } from "./store";
import React, { useEffect, useReducer } from "react";

const initialState = {
  open: localStorage.getItem("open") || false,
  message: localStorage.getItem("message") || "",
  type: localStorage.getItem('type') || 'others',
};

const reducer = (state, action) => {
  switch (action.type) {
    case "openAlert":
      return { ...state, open: true, message: action.payload.message, type: action.payload.type };
    case "closeAlert":
      return { ...state, open: false };
    default:
      return state;
  }
};


function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { open, message, type } = state;

  window.alert = (type, message) => {
    dispatch({
      type: "closeAlert",
    });
    setTimeout(() => {
    dispatch({
        type: "openAlert",
        payload: {
          type,
          message,
        },
      });
    }, 100);
  };

  useEffect(() => {
    localStorage.setItem("open", false);
    localStorage.setItem("message", "");
    localStorage.setItem("type", "others");
  }, []);

  return (
    <Provider store={store}>
      <CustomizedSnackbars
        type={type}
        message={message}
        open={open}
        onClose={() => {
          dispatch({
            type: "closeAlert",
          });
        }}
      />
      <Routes />
    </Provider>
  );
}

export default App;
